import './styles.scss';

import { Alias, SortDirectionEnum, TrackFlightTypeEnum, Trip } from '@/models/gen/graphql';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import useTripSettings, { ColumnKeyEnum } from '@/features/Trips/components/TripSettingsModal/hook';
import useTripTableState, { TripSortColumnEnum } from '@/features/Trips/components/TripsTable/hook';

import ActualCell from '@/features/Trips/components/HtmlTripsTable/Cells/ActualTimeCell';
import AssignDriverCell from '@/features/Trips/components/HtmlTripsTable/Cells/AssignDriverCell';
import AssignVehicleCell from '@/features/Trips/components/HtmlTripsTable/Cells/AssignVehicleCell';
import CombineCell from '@/features/Trips/components/HtmlTripsTable/Cells/CombineCell';
import CompletionCell from '@/features/Trips/components/HtmlTripsTable/Cells/CompletionCell';
import { DATE_FE_FORMAT_SHORT } from '@/constants';
import { Datetime } from '@/utils/dates';
import FcrCell from '@/features/Trips/components/HtmlTripsTable/Cells/FcrCell';
import FlagAndCommunicationCell from '@/features/Trips/components/HtmlTripsTable/Cells/FlagAndCommunicationCell';
import FormButton from '@/components/FormButton';
import { LoadingBlur } from '@/components/LoadingSpinner';
import LocationCell from '@/features/Trips/components/HtmlTripsTable/Cells/LocationCell';
import RateCell from '@/features/Trips/components/HtmlTripsTable/Cells/RateCell';
import Tooltip from '@/features/Trips/components/Tooltip';
import { TripsTableRowEventHandlers } from '@/features/Trips/components/TripsTable/TripTableRow';
import { formatTripTitle } from '@/features/Trips/components/TripsTable/utils';
import { getClasses } from '@/utils/strings';
import { zeroPadFlightNumber } from '@/utils/numbers';

const DEFAULT_MAX_HEIGHT = 300;
const ROW_HEIGHT = 45;
const FULLSCREEN_PADDING = ROW_HEIGHT * 2;
const OVER_SCAN = 20;
const MIN_HEIGHT = ROW_HEIGHT + FULLSCREEN_PADDING;
type HtmlTripsTableProps = {
  rows: string[];
  loading?: boolean;
  title: string;
  shortcuts?: (() => React.ReactNode) | React.ReactNode;
  fetchMore?: () => void;
  disableScroll?: boolean;
} & TripsTableRowEventHandlers;
const HtmlTripsTable = ({ rows, loading, title, shortcuts: Shortcuts, fetchMore, disableScroll, ...props }: HtmlTripsTableProps) => {
  // state
  const [printing, setPrinting] = useState(disableScroll !== undefined);
  const [maxHeight, setMaxHeight] = useState(DEFAULT_MAX_HEIGHT);
  // ref
  const tableRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: (): HTMLElement => tableRef.current,
    estimateSize: (): number => ROW_HEIGHT,
    overscan: OVER_SCAN,
    enabled: !printing,
  });

  // bind printing events
  const onStartPrint = useCallback((): void => setPrinting(true), []);
  const onEndPrint = useCallback((): void => setPrinting(false), []);

  useLayoutEffect((): (() => void) => {
    if (disableScroll !== undefined) return;
    window.addEventListener('beforeprint', onStartPrint);
    window.addEventListener('afterprint', onEndPrint);

    return (): void => {
      window.removeEventListener('beforeprint', onStartPrint);
      window.removeEventListener('afterprint', onEndPrint);
    };
  }, [onStartPrint, onEndPrint, disableScroll]);

  // calculate max height on mount
  useLayoutEffect((): void => {
    const node = document.querySelector('.RouteContent');
    const { y } = node.getBoundingClientRect();
    const result = window.innerHeight - y - FULLSCREEN_PADDING;
    setMaxHeight(result);
  }, [headerRef.current?.offsetHeight]);

  // Lazy Loading - Detect when user scrolls near the end and load more rows
  useEffect((): void => {
    if (disableScroll !== undefined || loading || !fetchMore) return;
    const list = rowVirtualizer.getVirtualItems();
    const lastVirtualItem = list[list.length - 1];
    if (!lastVirtualItem) return;
    const threshold = rows.length - (rows.length / 4 || -1);
    // If the last virtual item is within being rendered, load more
    if (lastVirtualItem.index >= threshold) fetchMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows.length, loading, fetchMore, rowVirtualizer.getVirtualItems()]);

  const contentHeight = (ROW_HEIGHT * rows?.length || ROW_HEIGHT) + ROW_HEIGHT;
  const tableContainerHeight = Math.min(maxHeight, contentHeight + ROW_HEIGHT);

  return (
    <div className={`HtmlTripsTable ${printing ? 'printing' : ''}`} ref={containerRef}>
      <div className="HtmlTripsTable-Header" ref={headerRef}>
        {title && <div className="HtmlTripsTable-Title">{title}</div>}
        {Shortcuts && <div className="HtmlTripsTable-Shortcuts">{Shortcuts instanceof Function ? <Shortcuts /> : Shortcuts}</div>}
      </div>
      <section
        className="table-container"
        ref={tableRef}
        style={{ height: printing ? 'auto' : tableContainerHeight, minHeight: MIN_HEIGHT }}
      >
        <div className="table-header">
          <TripTableHeader rows={rows} />
        </div>
        <div
          className="table-body"
          style={{
            position: 'relative',
            height: printing ? 'auto' : `${rowVirtualizer.getTotalSize()}px`,
            minHeight: ROW_HEIGHT,
          }}
        >
          <LoadingBlur loading={loading} />
          {!rows.length && !loading && (
            <div className="table-row empty">
              <div className="table-cell text-center">
                <i className="fa fa-inbox" />
                <span>No Records</span>
              </div>
            </div>
          )}
          {/* when printing render all rows */}
          {!!rows.length &&
            printing &&
            rows.map(
              (rowId: string, index: number): React.JSX.Element => (
                <TripsTableRow {...props} key={rowId} ref={rowVirtualizer.measureElement} data-index={index} index={index} rowId={rowId} />
              )
            )}
          {/* Render only visible rows */}
          {!!rows.length &&
            !printing &&
            rowVirtualizer.getVirtualItems().map(
              (virtualRow: VirtualItem): React.JSX.Element => (
                <TripsTableRow
                  {...props}
                  key={virtualRow.index}
                  ref={rowVirtualizer.measureElement}
                  data-index={virtualRow.index}
                  style={{
                    position: 'absolute',
                    top: `${virtualRow.start}px`,
                    left: 0,
                    height: `${virtualRow.size}px`,
                    transition: 'top 0.2s ease-in-out', // Smooth transition
                  }}
                  index={virtualRow.index}
                  rowId={rows[virtualRow.index]}
                />
              )
            )}
        </div>
      </section>
      <div className="HtmlTripsTable-Controls" style={{ height: printing ? contentHeight : tableContainerHeight, minHeight: MIN_HEIGHT }}>
        {/* Top */}
        <div>
          <FormButton
            className="border-gray"
            variant="secondary-subtle"
            onClick={(): void => {
              if (printing) {
                const node = document.querySelector('.RouteContent');
                if (!node) return;
                const scrollPosition = containerRef.current.getBoundingClientRect().bottom - window.innerHeight + FULLSCREEN_PADDING;
                node.scrollTop = scrollPosition;
                return;
              }
              rowVirtualizer.scrollToIndex(rows.length || 0);
            }}
            disabled={loading}
            icon={<i className="fa fa-chevron-down" />}
            tooltip="Scroll to Bottom"
          />
        </div>
        {/* Bottom */}
        <div>
          <FormButton
            className="border-gray"
            variant="secondary-subtle"
            onClick={(): void => {
              if (printing) {
                containerRef.current.scrollIntoView();
                return;
              }
              rowVirtualizer.scrollToIndex(0);
            }}
            disabled={loading}
            icon={<i className="fa fa-chevron-up" />}
            tooltip="Scroll to Top"
          />
        </div>
      </div>
    </div>
  );
};

type SortCellProps = {
  direction: SortDirectionEnum;
  onSort: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
};
const SortCell = ({ children, onSort, direction }: SortCellProps): React.JSX.Element => (
  <button className="sort" onClick={onSort}>
    <span>{children}</span>
    <Tooltip content={direction && (direction === SortDirectionEnum.Asc ? 'Ascending' : 'Descending')}>
      <span className="ms-1 d-print-none">
        <i className={`fa ${direction ? `fa-sort-${direction.toLowerCase()}` : 'fa-sort opacity-25'} `} />
      </span>
    </Tooltip>
  </button>
);

const TripTableSelectAll = ({ rows }: { rows: string[] }): React.JSX.Element => {
  const trips = useTripTableState(({ state }) => state.trips);
  const selected = useTripTableState(({ state }) => state.selected);
  const setState = useTripTableState(({ setState }) => setState);

  const filteredTrips = useMemo(() => {
    const output: Map<string, Trip> = new Map();
    rows.forEach((rowId) => output.set(rowId, trips.get(rowId)));
    return output;
  }, [rows, trips]);

  const isSelectAll = useMemo(() => {
    if (selected.size !== filteredTrips.size) return false;
    for (const tripId of selected.keys()) {
      if (!filteredTrips.has(tripId)) return false;
    }
    return true;
  }, [filteredTrips, selected]);

  const handleChange = useCallback(
    () => setState((current) => ({ ...current, selected: isSelectAll ? new Map() : filteredTrips })),
    [filteredTrips, isSelectAll, setState]
  );

  return <input type="checkbox" checked={isSelectAll} onChange={handleChange} />;
};

const TripTableHeader = React.memo(({ rows }: { rows: string[] }): React.JSX.Element => {
  const columns = useTripSettings(({ state }) => new Map(state.columns));
  const sorting = useTripTableState(({ state }) => state.sorting);
  const onSort = useTripTableState(({ state }) => state.onSort);
  const onSortOnly = useTripTableState(({ state }) => state.onSortOnly);

  const columnSortMap = useMemo(
    () =>
      sorting.reduce((acc, curr) => {
        acc[curr.column] = curr.direction;
        return acc;
      }, {}) as Record<TripSortColumnEnum, SortDirectionEnum>,
    [sorting]
  );

  const handleSort = useCallback(
    (column: TripSortColumnEnum): React.MouseEventHandler<HTMLButtonElement> =>
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        // is user holding shift key
        if (event.shiftKey) return onSort(column);
        return onSortOnly(column);
      },
    [onSort, onSortOnly]
  );
  return (
    <>
      <div className="table-header-cell w-xs-fixed d-print-none">
        <TripTableSelectAll rows={rows} />
      </div>
      {columns.get(ColumnKeyEnum.FLAGS) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <div className="w-100 d-flex justify-content-center gap-2">
            <i className="sv sv-flag-empty fs-5 {padding-top:0.05rem;}" />
            <i className="sv sv-message2 fs-5" />
          </div>
        </div>
      )}
      {columns.get(ColumnKeyEnum.TYPE) && (
        <div className="table-header-cell w-md-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.type)} direction={columnSortMap[TripSortColumnEnum.type]}>
            TYPE
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_DATE) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.latestScheduled)} direction={columnSortMap[TripSortColumnEnum.latestScheduled]}>
            DATE
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_TIME) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.latestScheduledUtc)} direction={columnSortMap.latestScheduledUtc}>
            SCH
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.ACTUAL) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.actual)} direction={columnSortMap[TripSortColumnEnum.actual]}>
            ACT
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.AIRPORT_CODE) && (
        <div className="table-header-cell w-md-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.airportCode)} direction={columnSortMap[TripSortColumnEnum.airportCode]}>
            CITY
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.IATA_CODE) && (
        <div className="table-header-cell  w-sm-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.servicerIataAirlineCode)} direction={columnSortMap.servicerIataAirlineCode}>
            AL
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.FLIGHT_NUMBER) && (
        <div className="table-header-cell">
          <SortCell onSort={handleSort(TripSortColumnEnum.flightNumber)} direction={columnSortMap[TripSortColumnEnum.flightNumber]}>
            FLT
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.PILOT) && (
        <div className="table-header-cell w-sm-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.pilots)} direction={columnSortMap[TripSortColumnEnum.pilots]}>
            PLT
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.ATTENDANT) && (
        <div className="table-header-cell w-sm-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.attendants)} direction={columnSortMap[TripSortColumnEnum.attendants]}>
            FA
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.DRIVER) && (
        <div className="table-header-cell w-md-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.driverId)} direction={columnSortMap[TripSortColumnEnum.driverId]}>
            DRV
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.VEHICLE) && (
        <div className="table-header-cell">
          <SortCell onSort={handleSort(TripSortColumnEnum.vehicleId)} direction={columnSortMap[TripSortColumnEnum.vehicleId]}>
            VAN
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.PU_LOCATION) && (
        <div className="table-header-cell w-lg-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.puLocationId)} direction={columnSortMap[TripSortColumnEnum.puLocationId]}>
            P/U
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.DO_LOCATION) && (
        <div className="table-header-cell w-lg-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.doLocationId)} direction={columnSortMap[TripSortColumnEnum.doLocationId]}>
            D/O
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.CLIENT) && (
        <div className="table-header-cell">
          <SortCell onSort={handleSort(TripSortColumnEnum.payerProviderId)} direction={columnSortMap[TripSortColumnEnum.payerProviderId]}>
            CLT
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.COMPLETION) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <i className="sv sv-completion fs-5" />
        </div>
      )}
      {columns.get(ColumnKeyEnum.FCR) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <i className="sv sv-fcr fs-5" />
        </div>
      )}
      {columns.get(ColumnKeyEnum.COMBINE) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <i className="sv sv-combine-icon fs-5 text-secondary" />
        </div>
      )}
      {columns.get(ColumnKeyEnum.RATE) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.rateAmount)} direction={columnSortMap[TripSortColumnEnum.rateAmount]}>
            RATE
          </SortCell>
        </div>
      )}
    </>
  );
});

type TripTableRowProps = TripsTableRowEventHandlers & {
  rowId: string;
  index: number;
  style?: React.CSSProperties;
};
const TripsTableRow = React.memo(
  React.forwardRef(
    (
      {
        rowId,
        index,
        onEditTrip,
        onEditFlag,
        onEditCommunication,
        onEditCompletion,
        onEditFcr,
        onEditCombine,
        onEditRateReport,
        onEditRate,
        confirmIllegalCombines,
        style,
        ...props
      }: TripTableRowProps,
      ref: React.ForwardedRef<HTMLTableRowElement>
    ): React.JSX.Element => {
      const columns = useTripSettings(({ state }) => state.columns);
      const data = useTripTableState(({ state }) => state.trips.get(rowId) || ({} as Trip));
      const isSelected = useTripTableState(({ state }) => state.selected.get(rowId));
      const onSelect = useTripTableState(({ state }) => state.onSelect);
      const onSelectOnly = useTripTableState(({ state }) => state.onSelectOnly);
      const onSetRow = useTripTableState(({ state }) => state.onSetRow);
      const refetch = useTripTableState(({ state }) => state.refetch);

      const scheduledDatetime = new Datetime(data.scheduled);
      const scheduledUtcDatetime = new Datetime(data.scheduledUtc);
      const flightNumber = zeroPadFlightNumber(data.flightNumber);
      const puLocationAliases = (data.puLocation?.aliases || []).map(({ name }: Alias): string => name);

      const doLocationAliases = (data.doLocation?.aliases || []).map(({ name }: Alias): string => name);

      const title = formatTripTitle(data.servicerIataAirlineCode, data.flightNumber, data.scheduled);

      return (
        <div
          {...props}
          ref={ref}
          style={style}
          onDoubleClick={(): void => {
            onSelectOnly(rowId);
            onEditTrip(data);
          }}
          className={getClasses(
            'table-row',
            isSelected ? 'selected' : '',
            data.kind,
            data.type,
            data.status,
            data.isLate ? 'LATE' : '',
            isUpcomingTrip(scheduledUtcDatetime) ? 'UPCOMING' : '',
            data.state?.completion ? 'COMPLETED' : '',
            data.deletedAt ? 'DELETED' : '',
            data.curbsideAt ? 'CURBSIDE' : ''
          )}
        >
          <div className="table-cell w-xs-fixed d-print-none">
            <input type="checkbox" name={`row-select_${index}`} checked={!!isSelected} onChange={(): void => onSelect(rowId)} />
          </div>
          {columns.get(ColumnKeyEnum.FLAGS) && (
            <div className="table-cell w-sm-fixed d-print-none">
              <FlagAndCommunicationCell
                flags={data.flags}
                hasCommunication={!!data.communications?.length}
                onFlagClick={(): void => onEditFlag(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled)}
                onCommunicationClick={(): void =>
                  onEditCommunication(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled, data.offset)
                }
              />
            </div>
          )}
          {columns.get(ColumnKeyEnum.TYPE) && <div className="table-cell w-md-fixed">{data.type || '--'}</div>}
          {columns.get(ColumnKeyEnum.SCHEDULED_DATE) && (
            <div className="table-cell w-sm-grow">{scheduledDatetime.format(DATE_FE_FORMAT_SHORT)}</div>
          )}
          {columns.get(ColumnKeyEnum.SCHEDULED_TIME) && <div className="table-cell w-sm-grow">{scheduledDatetime.time || '--'}</div>}
          {columns.get(ColumnKeyEnum.ACTUAL) && (
            <div className="table-cell flex-column gap-1 w-sm-grow">
              <ActualCell
                actual={data.trackFlight?.actual}
                arrivalGate={data.trackFlight?.arrivalGate}
                arrivalTerminal={data.trackFlight?.arrivalTerminal}
                label={data.trackFlight?.label}
                kind={data.kind}
                scheduled={data.scheduled}
              />
            </div>
          )}
          {columns.get(ColumnKeyEnum.AIRPORT_CODE) && <div className="table-cell w-md-fixed">{data.airportCode || '--'}</div>}
          {columns.get(ColumnKeyEnum.IATA_CODE) && <div className="table-cell w-sm-fixed">{data.servicerIataAirlineCode || '--'}</div>}
          {columns.get(ColumnKeyEnum.FLIGHT_NUMBER) && (
            <div
              className={`table-cell ${data.trackFlight?.type === TrackFlightTypeEnum.International ? 'bg-purple bg-opacity-15 text-dark' : undefined}`}
            >
              {data.loopName || flightNumber}
            </div>
          )}
          {columns.get(ColumnKeyEnum.PILOT) && <div className="table-cell w-sm-fixed">{data.pilots || 0}</div>}
          {columns.get(ColumnKeyEnum.ATTENDANT) && <div className="table-cell w-sm-fixed">{data.attendants || 0}</div>}
          {columns.get(ColumnKeyEnum.DRIVER) && (
            <div className={`table-cell w-md-grow ${!data.driver ? 'UNASSIGNED' : ''}`}>
              <AssignDriverCell
                rowId={data.id}
                title={title}
                airportCode={data.airportCode}
                actual={data.trackFlight?.actual}
                driver={data.driver}
                providerId={data.providerId}
                puLocationId={data.puLocationId}
                doLocationId={data.doLocationId}
                combineId={data.combineId}
                combineType={data.combineType}
                scheduled={data.scheduled}
                pilots={data.pilots}
                attendants={data.attendants}
                onSetRow={onSetRow}
                confirmIllegalCombines={confirmIllegalCombines}
              />
            </div>
          )}
          {columns.get(ColumnKeyEnum.VEHICLE) && (
            <div className="table-cell">
              <AssignVehicleCell
                actual={data.trackFlight?.actual}
                scheduled={data.scheduled}
                airportCode={data.airportCode}
                driverId={data.driverId}
                vehicle={data.vehicle}
                refetch={refetch}
                rowId={data.id}
              />
            </div>
          )}
          {columns.get(ColumnKeyEnum.PU_LOCATION) && (
            <div className="table-cell w-lg-grow">
              <LocationCell
                name={data.puLocation?.name}
                address={data.puLocation?.address}
                stateCode={data.puLocation?.stateCode}
                zipCode={data.puLocation?.zipCode}
                cityName={data.puLocation?.cityName}
                aliases={puLocationAliases}
              />
            </div>
          )}
          {columns.get(ColumnKeyEnum.DO_LOCATION) && (
            <div className="table-cell w-lg-grow">
              <LocationCell
                name={data.doLocation?.name}
                address={data.doLocation?.address}
                stateCode={data.doLocation?.stateCode}
                zipCode={data.doLocation?.zipCode}
                cityName={data.doLocation?.cityName}
                aliases={doLocationAliases}
              />
            </div>
          )}
          {columns.get(ColumnKeyEnum.CLIENT) && <div className="table-cell">{data.payerProvider?.displayName || '--'}</div>}
          {columns.get(ColumnKeyEnum.COMPLETION) && (
            <div className="table-cell w-sm-fixed d-print-none">
              <CompletionCell
                datetimeOfChange={data.state?.datetimeOfChange}
                displayName={data.state?.displayName}
                displayNameShort={data.state?.displayNameShort}
                onClick={(): void =>
                  onEditCompletion(data?.id, data?.servicerIataAirlineCode, data?.flightNumber, data?.scheduled, data?.state?.completion)
                }
              />
            </div>
          )}
          {columns.get(ColumnKeyEnum.FCR) && (
            <div className="table-cell w-sm-fixed d-print-none">
              <FcrCell
                count={data.fcrs?.length}
                onClick={(): void => onEditFcr(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled)}
              />
            </div>
          )}
          {columns.get(ColumnKeyEnum.COMBINE) && (
            <div className="table-cell w-sm-fixed d-print-none">
              {data.stops?.length > 0 && (
                <Tooltip content="Loop">
                  <i className="sv sv-sync fs-5 text-cyan {text-shadow:-0.02rem|0|var(--bs-cyan),|0|0.02rem|var(--bs-cyan),|0.02rem|0|var(--bs-cyan),|0|-0.02rem|var(--bs-cyan);}" />
                </Tooltip>
              )}
              {!data.stops?.length && (
                <CombineCell
                  combineId={data.combineId}
                  combineType={data.combineType}
                  onClick={(): void =>
                    onEditCombine(data.id, data.servicerIataAirlineCode, data.flightNumber, data.scheduled, data.combineId)
                  }
                />
              )}
            </div>
          )}
          {columns.get(ColumnKeyEnum.RATE) && (
            <div
              className="table-cell w-sm-grow"
              onDoubleClick={(event): void => {
                event.stopPropagation();
                onSelectOnly(rowId);
                onEditRate();
              }}
            >
              <RateCell rate={data.rate?.rate} rowId={data.id} onSetRow={onSetRow} onEditRateReport={onEditRateReport} />
            </div>
          )}
        </div>
      );
    }
  )
);

const isUpcomingTrip = (utcDatetime: Datetime): boolean =>
  utcDatetime.isSameUtc(undefined, 'day') && utcDatetime.diffUtc(undefined, 'hour') < 1;

export default React.memo(HtmlTripsTable);
