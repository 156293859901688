import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import { Datetime, Validation, queryInput, stringify, zeroPadFlightNumber } from '@/utils';
import { Location, Stop, StopTypeEnum, Trip, TripKindEnum } from '@/models/gen/graphql';
import React, { useCallback, useEffect, useMemo } from 'react';
import useOnChange, { EventObject, OnChange } from '@/hooks/useOnChange';

import ActionsDropdown from '@/components/ActionsDropdown';
import DateSelector from '@/components/DateSelector';
import DynamicContent from '@/components/DynamicContent';
import { EditTripsModalValidator } from '@/components/EditTripsModal/utils';
import FormField from '@/components/FormField';
import SelectAirlineIata from '@/components/SelectAirlineIata';
import SelectAirport from '@/components/SelectAirport';
import SelectClient from '@/components/SelectClient';
import SelectFlag from '@/components/SelectFlag';
import SelectKind from '@/components/SelectKind';
import SelectLocation from '@/components/SelectLocation';
import SelectStatus from '@/components/SelectStatus';
import SelectStopType from '@/components/SelectStopType';
import SelectType from '@/components/SelectType';
import TippyWhen from '@/components/TippyWhen';
import { createComponentState } from '@/state';
import { getTripForModal } from '@/api/services/trips/searchTrips';
import { useNavigate } from 'react-router-dom';
import useTrips from '@/hooks/useTrips';
import useUuid from '@/hooks/useUuid';
import { getActivityByTripId } from '@/api/services/activity/SearchActivity';
import { getFlagByTripId } from '@/api/services/flag/SearchFlag';
import { getStopByTripId } from '@/api/services/stop/SearchStop';

type TripForm = Omit<Partial<Trip>, 'stops' | 'flightNumber'> & {
  scheduledDays?: string[];
  comments?: string;
  days?: string[];
  increment?: boolean;
  doScheduledDatetime?: string;
  doAirportFilter?: string;
  flightNumber?: number | string;
  stops?: TripStop[];
};
export type UseTripViewState = {
  trip?: TripForm;
  original?: Partial<Trip>;
  loading?: boolean;
  loopOrFlightNumber?: string;
};
const initUseTripsModalState: UseTripViewState = {
  trip: {},
  original: {},
  loading: false,
  loopOrFlightNumber: '0',
};
export const useTripViewState = createComponentState(initUseTripsModalState);
const TripView = ({
  data,
  mode,
  selected,
  onSubmit,
  onHide,
}: {
  data: Partial<Trip>;
  mode: 'editing' | 'creating';
  selected?: Partial<Trip>[];
  onSubmit?: (state: UseTripViewState, persist?: boolean) => void;
  onHide?: () => void;
}) => {
  const navigate = useNavigate();
  const [, { handleCreateTripBulk, handleUpdateTripBulk }] = useTrips();
  const [state, setState] = useTripViewState(({ state, setState }) => [state, setState]);
  const { trip = {}, original = {}, loopOrFlightNumber } = state;
  const onChange = useOnChange(setState);
  const stopsDisabled = mode === 'editing' && selected.length > 1;
  const handleHide = () => {
    onHide?.();
  };
  const handleSubmit = async (): Promise<void> => {
    trip.pilots = trip?.pilots || 0;
    trip.attendants = trip?.attendants || 0;
    trip.flightNumber = parseInt(`${trip?.flightNumber || 0}`);
    trip.loopName = trip?.loopName || '';
    if (trip?.id) {
      await handleUpdateTripBulk(trip, selected?.length > 1 ? selected || [] : [original]);
    } else {
      await handleCreateTripBulk(trip);
    }
    onSubmit?.(state);
  };
  const onSaveAndCopy = async (): Promise<void> => {
    trip.pilots = trip?.pilots || 0;
    trip.attendants = trip?.attendants || 0;
    trip.flightNumber = parseInt(`${trip?.flightNumber || 0}`);
    trip.loopName = trip?.loopName || '';
    await handleCreateTripBulk(trip, selected?.length > 1 ? selected || [] : [original]);
    onSubmit?.(state, true);
  };

  const selectedCount = Object.values(selected).length || 0;
  const uuid = useUuid();

  const onAddStop = (): void => {
    const currentDatetime = new Datetime();
    setState(
      (current: UseTripViewState): UseTripViewState => ({
        ...current,
        trip: {
          ...(current?.trip || {}),
          stops: [
            ...(current?.trip?.stops || []),
            {
              id: new Date().getTime().toString(),
              type: StopTypeEnum.Pu,
              airportFilter: current?.trip?.airportCode,
              scheduled: current?.trip?.scheduled
                ? new Datetime(current?.trip?.scheduled).setTime(currentDatetime.fullTime).toString()
                : currentDatetime.toString(),
            },
          ],
        },
      })
    );
  };
  const onBlurFlightNumber = (): void => {
    setState((current: UseTripViewState): UseTripViewState => {
      const value = current?.loopOrFlightNumber || '';
      if (!value)
        return { ...current, loopOrFlightNumber: '', trip: { ...(current?.trip || {}), flightNumber: undefined, loopName: undefined } };
      if (value.length > 4 || value.match(/\D/g))
        return { ...current, trip: { ...(current?.trip || {}), flightNumber: undefined, loopName: value } };
      return {
        ...current,
        loopOrFlightNumber: value.replace(/^[0]+/, '').padStart(4, '0'),
        trip: { ...(current?.trip || {}), flightNumber: value, loopName: undefined },
      };
    });
  };

  const validity = useMemo(() => {
    if (mode === 'creating' && !EditTripsModalValidator.keys.includes('scheduledDays')) EditTripsModalValidator.keys.push('scheduledDays');
    if (mode === 'editing' && EditTripsModalValidator.keys.includes('scheduledDays'))
      EditTripsModalValidator.keys.splice(EditTripsModalValidator.keys.indexOf('scheduledDays'), 1);
    const result = (EditTripsModalValidator || (() => ({})))(trip, selected);
    return result;
  }, [mode, selected, trip]);
  const isValid = Validation.isValid(validity);

  const MemoizedTripStop = useMemo(() => {
    const TripsStopComponent = (props: { index: number; data: Partial<Stop> }): JSX.Element => {
      return (
        <TripStop
          onDelete={(): void =>
            setState(
              (current: UseTripViewState): UseTripViewState => ({
                ...current,
                trip: {
                  ...(current?.trip || {}),
                  stops: (current?.trip?.stops || []).filter((_stop: Partial<Stop>, i: number): boolean => i !== props?.index),
                },
              })
            )
          }
          onChange={(event: EventObject): void => {
            const { name, value } = event?.target || {};
            switch (name.split('.').pop()) {
              case 'locationId':
                return value !== '' ? onChange(event) : undefined;
              case 'airportFilter':
                onChange({ target: { name: `trip.stops.${props?.index}.locationId`, value: '' } });
              default:
                return onChange(event);
            }
          }}
          valid={{
            locationId: Validation.isValidUUID(props?.data?.locationId) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
          }}
          options={{
            filterLocationByAirport: true,
          }}
          disabled={mode === 'editing' && stopsDisabled}
          readOnly={mode === 'editing' && stopsDisabled}
          tripScheduledTime={trip?.scheduled}
          {...props}
        />
      );
    };
    return TripsStopComponent;
  }, [mode, onChange, setState, stopsDisabled]);

  const getTripData = useCallback(
    async (id: string): Promise<void> => {
      setState((current: UseTripViewState): UseTripViewState => ({ ...current, loading: true }));
      try {
        const trip = await getTripForModal(id);
        setState((current: UseTripViewState): UseTripViewState => ({ ...current, trip, original: trip }));
      } catch (error) {
        console.error(error);
      } finally {
        setState((current: UseTripViewState): UseTripViewState => ({ ...current, loading: false }));
      }
    },
    [setState]
  );

  const getAdditionalTripData = useCallback(
    async (id: string): Promise<void> => {
      setState((current: any): any => ({ ...current, loading: true }));
      try {
        // Run all the async functions concurrently
        const [remarks, flags, stops] = await Promise.all([getActivityByTripId(id), getFlagByTripId(id), getStopByTripId(id)]);

        // Update the state once all the data is loaded
        setState((current: any): any => {
          const updatedState = {
            ...current,
            trip: { ...(current.trip || {}), remarks, flags, stops },
            original: { ...current.original, remarks, flags, stops },
          };
          return updatedState;
        });
      } catch (error) {
        console.error(error);
      } finally {
        setState((current: any): any => ({ ...current, loading: false }));
      }
    },
    [setState]
  );

  useEffect((): void => {
    if (typeof data === 'string') {
      getTripData(data);
      return;
    }
    setState((current) => ({
      ...current,
      trip: data || {},
      original: data || {},
      loopOrFlightNumber: data?.loopName || zeroPadFlightNumber(`${data?.flightNumber || 0}`),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTripData, data]);

  useEffect((): void => {
    if (!trip?.id) return;
    getAdditionalTripData(trip?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdditionalTripData, trip?.id]);

  const tabActions = useMemo(() => {
    const actions = [];
    if (mode === 'editing')
      actions.push({
        key: 'Go To Manifest',
        label: 'Go To Manifest',
        onClick: (): void => {
          handleHide();
          navigate(`/manifests/${trip?.manifestImportId}`);
        },
      });
    return actions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, navigate, trip]);

  return (
    <Container fluid>
      <div className="w-100 d-flex justify-content-between">
        <DynamicContent.Title className="flex-grow-1">{mode === 'editing' ? 'Edit Trip' : 'Create Trip'}</DynamicContent.Title>
        {tabActions?.length > 0 && <ActionsDropdown items={tabActions} onClick={() => {}} />}
      </div>

      <FormField
        label="Type:"
        placeholder="Select Type"
        name="trip.type"
        value={trip?.type || ''}
        onChange={onChange}
        valid={validity?.type?.valid}
        searchable
        options={{
          input: {
            as: SelectType,
          },
        }}
      />
      {mode === 'creating' && (
        <DateSelector
          name="trip.scheduledDays"
          label="P/U Date:"
          value={trip?.['scheduledDays'] || []}
          onChange={onChange}
          valid={validity?.scheduledDays?.valid}
          selector="day"
          range={false}
          multiple={mode === 'creating' ? true : false}
          inline
          disabled
        />
      )}
      {mode === 'editing' && (
        <FormField
          name="trip.scheduled"
          label="P/U Date:"
          type="date"
          value={trip?.scheduled || ''}
          onChange={(event: EventObject): void => {
            const { value } = event?.target || {};
            const currentScheduledTime = trip?.scheduled?.split('T')?.[1];
            const newScheduledTime = currentScheduledTime ? value.concat(' ', currentScheduledTime) : value;
            const result: EventObject[] = [{ target: { name: 'trip.scheduled', value: new Datetime(newScheduledTime).toString() } }];
            if (trip?.stops?.length) {
              const originalStops = [...(trip?.stops || [])];
              const stops = originalStops.map(
                (stop: Stop): Stop => ({
                  ...stop,
                  scheduled: value ? new Datetime(stop?.scheduled || undefined).setDate(value).toString() : null,
                })
              );
              result.push({ target: { name: 'trip.stops', value: stops } });
            }
            onChange.bulk(result);
          }}
          valid={validity?.scheduled?.valid}
          disabled={!!trip?.increment}
          feedback={trip?.increment ? 'Disable increment to edit scheduled date.' : ''}
        />
      )}
      <FormField
        label="Client:"
        placeholder="Select Client"
        name="trip.payerProviderId"
        value={trip?.payerProviderId || ''}
        onChange={(event) => {
          const value = event?.target?.value;
          onChange.bulk([{ target: { name: 'trip.providerId', value } }, { target: { name: 'trip.payerProviderId', value } }]);
        }}
        valid={validity?.payerProviderId?.valid}
        searchable
        options={{
          input: {
            as: SelectClient,
          },
        }}
      />
      <FormField
        label="Airline:"
        placeholder="Select Airline"
        name="trip.servicerIataAirlineCode"
        value={trip?.servicerIataAirlineCode || ''}
        onChange={onChange}
        valid={validity?.servicerIataAirlineCode?.valid}
        searchable
        options={{
          input: {
            as: SelectAirlineIata,
          },
        }}
      />
      <FormField
        label="Airport:"
        placeholder="Select Airport"
        name="trip.airportCode"
        value={trip?.airportCode || ''}
        onChange={(event: EventObject): void => {
          const { value } = event?.target || {};
          if (!value) return;
          onChange.bulk([
            { target: { name: 'trip.airportCode', value } },
            { target: { name: 'trip.doLocationId', value } },
            { target: { name: 'trip.doAirportFilter', value } },
            {
              target: {
                name: 'trip.stops',
                value: (trip?.stops || []).map(
                  (stop: Partial<Stop>): TripStop => ({
                    ...stop,
                    airportFilter: value,
                    locationId: '',
                  })
                ),
              },
            },
          ]);
        }}
        valid={validity?.airportCode?.valid}
        searchable
        options={{
          input: {
            as: SelectAirport,
          },
        }}
      />
      <FormField
        label={'Arrival/\nDeparture:'}
        placeholder="Select Kind"
        name="trip.kind"
        value={trip?.kind || ''}
        onChange={(event): void => {
          const { value } = event?.target || {};
          const { puLocationId = '', doLocationId = '' } = data || {};
          onChange.bulk([
            { target: { name: 'trip.kind', value } },
            { target: { name: 'trip.puLocationId', value: doLocationId } },
            { target: { name: 'trip.doLocationId', value: puLocationId } },
          ]);
        }}
        valid={validity?.kind?.valid}
        searchable
        options={{
          input: {
            as: SelectKind,
          },
        }}
      />
      <FormField
        label="Flight Number:"
        name="trip.loopOrFlightNumber"
        value={loopOrFlightNumber}
        onChange={(event) => {
          const { value } = event?.target || {};
          const isLoopName = value.length > 4 || value.match(/\D/g);
          const flightNumber = !isLoopName ? value : undefined;
          const loopName = isLoopName ? value : undefined;
          setState(
            (current: UseTripViewState): UseTripViewState => ({
              ...current,
              loopOrFlightNumber: value,
              trip: { ...(current?.trip || {}), flightNumber, loopName },
            })
          );
        }}
        onBlur={onBlurFlightNumber}
        valid={validity?.flightNumber?.valid || validity?.loopName?.valid}
      />
      <FormField
        label="PLT:"
        type="number"
        name="trip.pilots"
        value={trip?.pilots || 0}
        onChange={onChange}
        valid={trip?.pilots > 0 || trip?.attendants > 0}
        min={0}
        max={255}
      />
      <FormField
        label="F/A:"
        type="number"
        name="trip.attendants"
        value={trip?.attendants || 0}
        onChange={onChange}
        valid={trip?.attendants > 0 || trip?.pilots > 0}
        min={0}
        max={255}
      />
      <FormField
        type="number"
        label="Crew ID:"
        name="trip.crewId"
        value={trip?.crewId || ''}
        onChange={onChange.int}
        valid={validity?.crewId?.valid}
        options={{
          input: {
            className: 'CrewId',
          },
        }}
      />
      <FormField
        label="First Name:"
        name="trip.firstName"
        value={trip?.firstName || ''}
        onChange={onChange}
        valid={validity?.firstName?.valid}
      />
      <FormField
        label="Last Name:"
        name="trip.lastName"
        value={trip?.lastName || ''}
        onChange={onChange}
        valid={validity?.lastName?.valid}
      />
      <FormField
        label={'Flags:'}
        placeholder="No Flags"
        name="trip.flags"
        value={(trip?.flags || []).map((node) => node.id || node) as string[]}
        onChange={onChange}
        disabled={selectedCount > 1}
        searchable
        options={{
          input: { as: SelectFlag, className: 'Flags' },
        }}
      />
      <FormField
        label={'Status:'}
        placeholder="Select Status"
        name="trip.status"
        value={trip?.status || ''}
        onChange={(event: EventObject): void => (event?.target?.value ? onChange(event) : undefined)}
        valid={validity?.status?.valid}
        searchable
        options={{
          input: { as: SelectStatus, className: 'Status' },
        }}
      />
      <FormField
        label="Include in\nFuture Manifests?"
        id={`future_${uuid}`}
        type="switch"
        name="trip.fromManifest"
        checked={trip?.fromManifest !== undefined ? !!trip?.fromManifest : true}
        onChange={onChange.toggleInt}
      />
      <FormField label="Split:" type="number" name="trip.split" value={trip?.split || ''} onChange={onChange} />
      <FormField
        type="textarea"
        label="Comments:"
        name="trip.comments"
        value={trip?.comments || ''}
        onChange={onChange}
        options={{ input: { className: 'Comments {height:7rem;resize:none;}' } }}
      />
      {mode === 'editing' && (
        <>
          <FormField
            label="Increment?"
            id={`increment_${uuid}`}
            type="switch"
            name="trip.increment"
            checked={!!trip?.increment}
            onChange={onChange.toggle}
            condensed
          />
          <FormField
            label="Days:"
            type="number"
            name="trip.days"
            value={trip?.days || 0}
            disabled={!trip?.increment}
            onChange={onChange.int}
            condensed
          />
        </>
      )}

      <DynamicContent.Title className="my-5">Trip Stops</DynamicContent.Title>
      <div className="d-flex justify-content-center align-items-center w-100 gap-3 mt-2">
        <Col xs={2}>
          <div className="d-flex flex-column justify-content-center">
            <small className="text-center">RFP</small>
            <FormField
              type="time"
              name="trip.puActualDatetime"
              value={trip?.puActualDatetime ? new Datetime(trip?.puActualDatetime).fullTime : ''}
              onChange={onChange?.time || onChange}
              disabled={stopsDisabled}
              valid={validity?.puActualDatetime}
              options={{ input: { className: 'Ready p-0' } }}
              condensed
            />
          </div>
        </Col>
        <Col xs={2}>
          <div className="d-flex flex-column justify-content-center">
            <small className="text-center">ACT</small>
            <FormField
              type="time"
              name="trip.puCompletedDatetime"
              value={trip?.puCompletedDatetime ? new Datetime(trip?.puCompletedDatetime).fullTime : ''}
              onChange={onChange?.time || onChange}
              disabled={stopsDisabled}
              valid={validity?.puCompletedDatetime}
              options={{ input: { className: 'Actual' } }}
              condensed
            />
          </div>
        </Col>
      </div>
      <Row className="mt-4">
        <Col xs={2} className="pe-0"></Col>
        <Col xs={6} className="ps-1 pe-0">
          <small className="ps-4">LOCATION</small>
        </Col>
        <Col xs={2} className="ps-1 pe-0">
          <small className="ps-3">CITY</small>
        </Col>
        <Col xs={2} className="ps-1">
          <small className="ps-2">SCH</small>
        </Col>
      </Row>
      <TripStop
        data={{
          type: StopTypeEnum.Pu,
          airportFilter: trip?.airportCode || '',
          locationId: trip?.puLocationId,
          scheduled: trip?.scheduled,
        }}
        valid={{ locationId: validity?.puLocationId?.valid, scheduled: validity?.scheduled?.valid }}
        index={-1}
        disabled={stopsDisabled}
        tripScheduledTime={trip?.scheduled}
        onChange={(event: EventObject) => {
          const { name, value } = event?.target || {};
          switch (name.split('.').pop()) {
            case 'locationId':
              return value !== '' ? onChange({ target: { name: 'trip.puLocationId', value } }) : undefined;
            default:
              return onChange({ target: { name: name.split('.').pop(), value } });
          }
        }}
        options={{
          autoSelectLocation: trip?.kind === TripKindEnum.Arr,
          disableType: true,
          hideCity: true,
          location: {
            props: {
              query: {
                airportCode: trip?.airportCode || null,
              },
            },
          },
        }}
      />
      {trip?.stops?.length > 0 && trip?.stops.map((stop, s) => <MemoizedTripStop key={s} index={s} data={stop} />)}
      <TripStop
        data={{
          type: StopTypeEnum.Do,
          locationId: trip?.doLocationId,
          scheduled: trip?.doScheduledDatetime,
          airportFilter: trip?.doAirportFilter || trip?.doLocation?.airports?.[0]?.airportCode || trip?.airportCode || '',
        }}
        tripScheduledTime={trip?.scheduled}
        valid={{ locationId: validity?.doLocationId?.valid }}
        disabled={stopsDisabled}
        index={trip?.stops?.length || 0}
        onChange={(event: EventObject) => {
          const { name, value } = event?.target || {};
          switch (name.split('.').pop()) {
            case 'locationId':
              return value !== '' ? onChange({ target: { name: 'trip.doLocationId', value } }) : undefined;
            case 'airportFilter':
              onChange.bulk([{ target: { name: 'trip.doLocationId', value: '' } }, { target: { name: 'trip.doAirportFilter', value } }]);
            default:
              return onChange({ target: { name: name.split('.').pop(), value } });
          }
        }}
        options={{
          autoSelectLocation: trip?.kind === TripKindEnum.Dep,
          filterLocationByAirport: true,
          disableType: true,
          disableTime: true,
        }}
      />

      <Button name="ADD_STOP" className="w-100 my-3 add-stop-btn" variant="outline-gray" onClick={onAddStop}>
        <i className="fa fa-plus" /> ADD STOP
      </Button>
      {mode === 'creating' && (
        <TippyWhen isTrue={!isValid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
          <Button className="w-100 my-2" variant="black" onClick={onSaveAndCopy} disabled={!isValid}>
            SAVE COPY
          </Button>
        </TippyWhen>
      )}
      <Row className="mb-4">
        <Col xs={6}>
          <Button name="CANCEL" className="w-100" variant="secondary" onClick={onHide}>
            CANCEL
          </Button>
        </Col>
        <Col xs={6}>
          <TippyWhen isTrue={!isValid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
            <Button name="SUBMIT" className="w-100" variant="primary" onClick={handleSubmit} disabled={!isValid}>
              SAVE
            </Button>
          </TippyWhen>
        </Col>
      </Row>
    </Container>
  );
};

export default TripView;

type TripStop = Partial<Stop> & {
  airportFilter?: string;
};
interface TripStopProps {
  data: TripStop;
  onDelete?: () => void;
  onChange: OnChange;
  index: number;
  disabled?: boolean;
  readOnly?: boolean;
  valid?: { locationId?: boolean | Validation.ValidityType; scheduled?: boolean | Validation.ValidityType };
  options?: {
    autoSelectLocation?: boolean;
    filterLocationByAirport?: boolean;
    location?: {
      props?: any;
    };
    disableType?: boolean;
    disableTime?: boolean;
    hideCity?: boolean;
  };
  tripScheduledTime?: string;
}
const TripStopComponent = ({
  data: stop,
  onDelete,
  onChange,
  index: s,
  disabled,
  readOnly,
  valid,
  options,
  tripScheduledTime,
}: TripStopProps): JSX.Element => {
  const filters = options?.location?.props?.filter || {};
  return (
    <Row className="TripStop align-items-center py-2">
      <Col xs={2} className="pe-0">
        <InputGroup size={onDelete || !disabled ? 'sm' : undefined}>
          {onDelete && !disabled && (
            <InputGroup.Text>
              <Button variant="icon" className="p-0" onClick={onDelete}>
                <i className="fa fa-minus-circle text-danger" />
              </Button>
            </InputGroup.Text>
          )}
          <SelectStopType
            name={`trip.stops.${s}.type`}
            value={stop?.type || 'PU'}
            onChange={onChange}
            disabled={options?.disableType || disabled}
            readOnly={readOnly}
            placeholder="--"
          />
        </InputGroup>
      </Col>
      <Col xs={6} className="ps-1 pe-0">
        <FormField
          {...(options?.location?.props || {})}
          query={{
            ...filters,
            airportCode: queryInput(filters?.airportCode || stop?.airportFilter),
          }}
          inputOptions={{ removeAirportPrefix: true, autoSelectLocation: !!options?.autoSelectLocation }}
          placeholder="Select Location"
          name={`trip.stops.${s}.locationId`}
          value={stop?.locationId}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          valid={valid?.locationId}
          searchable
          onCreate={(location: Location): void => onChange({ target: { name: `trip.stops.${s}.locationId`, value: location?.id } })}
          options={{ input: { as: SelectLocation }, group: { className: 'ps-1' } }}
          inline
          condensed
        />
      </Col>
      <Col xs={2} className="ps-1 pe-0">
        <FormField
          placeholder="City"
          name={`trip.stops.${s}.airportFilter`}
          value={stop?.airportFilter}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          searchable
          options={{ input: { as: SelectAirport }, group: { className: `ps-1 ${options?.hideCity ? 'd-none' : ''}` } }}
          condensed
        />
      </Col>
      <Col xs={2} className="ps-1">
        <InputGroup className="p-relative">
          <FormField
            type="time"
            name={`trip.stops.${s}.scheduled`}
            key={`trip.stops.${s}.scheduled`}
            value={stop?.scheduled ? new Datetime(stop?.scheduled).time : ''}
            onChange={(event: EventObject) => {
              const { name, value } = event.target;
              const formattedTime = new Datetime(tripScheduledTime).setTime(value).toString();
              const input = { target: { name, value: formattedTime } };
              onChange?.time ? onChange.time(input) : onChange(input);
            }}
            valid={valid?.scheduled}
            disabled={options?.disableTime || disabled}
            readOnly={readOnly}
            options={{ input: { className: 'Scheduled p-0' } }}
            condensed
          />
        </InputGroup>
      </Col>
    </Row>
  );
};
const TripStop = React.memo(TripStopComponent, (prev: Readonly<TripStopProps>, next: Readonly<TripStopProps>): boolean =>
  stringify.compare(prev?.data, next?.data)
);
