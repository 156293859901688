import './styles.scss';

import { Badge, Button, FormControl, FormControlProps, ListGroup, ListGroupItem } from 'react-bootstrap';
import { ComponentPropsWithoutRef, Dispatch, ForwardedRef, ReactNode, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import LoadingSpinner, { LoadingBlur } from '@/components/LoadingSpinner';
import { getClasses, stringify } from '@/utils';

import React from 'react';
import Tippy from '@tippyjs/react';
import { autoPlacement } from '@floating-ui/react';
import useLocale from '@/hooks/useLocale';

export type DropdownChangeHandler =
  | ((value: string, item: DropdownItem) => boolean)
  | ((value: string, item: DropdownItem) => void)
  | Dispatch<React.SetStateAction<string>>;
export type DropdownOptions = {
  indicators?: JSX.Element;
  loading?: boolean;
  onToggle?: (open: boolean) => void;
  locale?: Record<string, string>;
  disabled?: boolean;
  showChevron?: boolean;
  showClearButton?: boolean;
  showFilterCount?: boolean;
  showLoadingSpinner?: boolean;
  showSelectAll?: boolean;
  autoSelect?: boolean;
  openOnMount?: boolean;
};
export type DropdownProps = Omit<Partial<FormControlProps>, 'onChange'> & {
  name: string;
  value: string | string[];
  items: DropdownItem[];
  onChange: DropdownChangeHandler;
  onBlur?: DropdownChangeHandler;
  onEnter?: DropdownChangeHandler;
  options?: DropdownOptions;
};
export type DropdownItem = {
  value?: string;
  group?: string[];
  label?: string;
  display?: ReactNode;
  keywords?: string[];
  info?: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  action?: boolean;
  type?: string;
};

const Dropdown = (props: DropdownProps): ReactNode => {
  const {
    onChange,
    onEnter,
    items = [],
    options: {
      loading = false,
      onToggle = (): void => {},
      locale: customLocale = {},
      indicators = <></>,
      disabled = false,
      showChevron = true,
      showClearButton = true,
      showFilterCount = false,
      showLoadingSpinner = true,
      showSelectAll = true,
      autoSelect = true,
      openOnMount = false,
    } = {},
    ...formControlProps
  } = props;
  const [open, setOpen] = useState<boolean>(openOnMount === true);
  const [inputValue, setInputValue] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const didSelect = useRef<boolean>(false);
  const value = useMemo(
    (): string[] =>
      Array.from(new Set(Array.isArray(props?.value) ? props?.value : [props?.value])).filter(
        (val: string): boolean => val !== undefined && val !== null && val !== ''
      ),
    [props?.value]
  );
  const locale = useLocale(customLocale);
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      autoPlacement({
        allowedPlacements: ['bottom', 'top'],
        padding: { top: 500 },
      }),
      offset(5),
      size({
        apply({ rects, availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });
  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([role, dismiss, listNav]);

  const filteredItems = useMemo((): DropdownItem[] => {
    return items
      .filter((item: DropdownItem): boolean => inputValue === '' || !item.type || item.type === 'item')
      .filter(
        (item: DropdownItem): boolean =>
          item.value === '' ||
          inputValue === '' ||
          ((!item.type || item.type === 'item') &&
            inputValue
              .split(' ')
              .every((input: string): boolean =>
                (item?.keywords || []).some((keyword: string): boolean => keyword.toLowerCase().startsWith(input.toLowerCase()))
              ))
      )
      .sort((a: DropdownItem, b: DropdownItem): number => (inputValue.length === 0 ? 0 : a.keywords.length - b.keywords.length));
  }, [inputValue, items]);
  const handleChange = (item?: DropdownItem): void => {
    setInputValue('');
    const stayOpen = onChange?.(item?.value, item);
    setOpen(!!stayOpen);
    refs?.domReference?.current?.focus?.();
    didSelect.current = true;
  };

  const lastOpen = useRef<boolean>(false);
  useEffect((): void => {
    if (open === lastOpen.current) return;
    lastOpen.current = open;
    onToggle?.(open);
    if (!open) {
      setActiveIndex(null);
      setInputValue('');
    }
  }, [onToggle, open]);
  useEffect((): void => {
    if (
      autoSelect !== false &&
      (filteredItems?.length === 1 ||
        (filteredItems?.[0]?.keywords?.length === 1 &&
          filteredItems?.[0]?.keywords?.find?.((key: string): boolean => key?.toLowerCase?.() === inputValue?.toLowerCase?.())) ||
        filteredItems?.[0]?.keywords?.join(' ')?.toLowerCase?.() === inputValue?.toLowerCase?.()) &&
      !filteredItems?.[0]?.disabled
    ) {
      setActiveIndex(0);
    } else {
      setActiveIndex(null);
    }
  }, [filteredItems, inputValue, autoSelect]);

  useEffect((): void => {
    if (open === false && formControlProps?.onBlur) formControlProps?.onBlur(null);
  }, [open]);

  return (
    <div className={getClasses('Dropdown', props?.className)}>
      <FormControl
        {...formControlProps}
        {...getReferenceProps({
          name: props?.name,
          tabIndex: 0,
          className: getClasses('Dropdown-Input', !value?.length ? 'is-empty' : 'is-selected'),
          ref: refs.setReference,
          onChange: (event: React.ChangeEvent<HTMLInputElement>): void => {
            setOpen(true);
            setInputValue(event.target.value);
          },
          value: inputValue,
          disabled,
          placeholder: locale(
            value
              ?.map?.((val: string): ReactNode => items.find((item: DropdownItem): boolean => item.value === val)?.label || '')
              ?.join(', ') || 'Select...'
          ),
          onClick: (): void => {
            setOpen((current: boolean): boolean => !current);
            setInputValue('');
          },
          onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
            switch (event.key) {
              case 'Enter': {
                if (activeIndex !== undefined && activeIndex !== null && filteredItems[activeIndex]) {
                  setInputValue(filteredItems[activeIndex].value);
                  handleChange(filteredItems[activeIndex]);
                } else if (didSelect.current && onEnter) {
                  onEnter?.(filteredItems?.[activeIndex]?.value, filteredItems?.[activeIndex]);
                  didSelect.current = false;
                } else if (!open) {
                  setOpen(true);
                }
                break;
              }
              case 'Delete':
              case 'Backspace': {
                if (inputValue === '') {
                  handleChange();
                }
                break;
              }
              case 'Tab': {
                if (activeIndex !== undefined && activeIndex !== null && filteredItems[activeIndex]) {
                  setInputValue(filteredItems[activeIndex].value);
                  handleChange(filteredItems[activeIndex]);
                }
                setOpen(false);
                break;
              }
              default: {
                break;
              }
            }
          },
          autoComplete: 'off',
        })}
        style={{
          ...(formControlProps?.style || {}),
          paddingRight: `${(indicators?.props?.children?.length || 0) + [showChevron, showClearButton, showFilterCount].filter((val: boolean): boolean => val !== false).length * 1}rem`,
        }}
      />
      <div className="Dropdown-Inidicators">
        {showClearButton !== false && (inputValue?.length > 0 || value?.length > 0) && !disabled && (
          <Tippy content={locale('Clear')}>
            <Button className="Dropdown-Clear" tabIndex={-1} variant="icon" onClick={(): void => handleChange()}>
              <i className="fa fa-times" />
            </Button>
          </Tippy>
        )}
        {showFilterCount !== false &&
          filteredItems.filter((item: DropdownItem): boolean => !item?.action).length !==
            items.filter((item: DropdownItem): boolean => !item?.action).length && (
            <Tippy content={locale('Filtered Options')}>
              <div className="Dropdown-Count">
                <Badge bg="green">
                  <span>
                    <i className="fa fa-filter" />
                  </span>
                  <span>
                    {filteredItems.filter((item: DropdownItem): boolean => !item?.action).length}/
                    {items.filter((item: DropdownItem): boolean => !item?.action).length}
                  </span>
                </Badge>
              </div>
            </Tippy>
          )}
        {indicators}
        {showLoadingSpinner !== false && loading && (
          <Button tabIndex={-1} variant="icon">
            <i className="fa fa-spinner fa-pulse" />
          </Button>
        )}
        {showChevron !== false && (
          <Button
            className={getClasses('Dropdown-Chevron', formControlProps?.isValid || formControlProps?.isInvalid ? 'invisible' : undefined)}
            tabIndex={-1}
            variant="icon"
          >
            <i className="fa fa-chevron-down" />
          </Button>
        )}
      </div>
      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
            <ListGroup
              {...getFloatingProps({
                tabIndex: -1,
                className: 'Dropdown-List',
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                  overflow: 'auto',
                },
              })}
            >
              {filteredItems.length > 0 && <LoadingBlur loading={loading} size={filteredItems.length > 2 ? 'md' : 'sm'} />}
              {filteredItems.length === 0 && (
                <ListGroupItem className="Dropdown-Empty">
                  {loading && <LoadingSpinner size="sm" />}
                  {!loading && (
                    <>
                      <span>
                        <i className="fa fa-inbox" />
                      </span>
                      <em>{locale('No Options')}</em>
                    </>
                  )}
                </ListGroupItem>
              )}
              {filteredItems.map((item: DropdownItem, index: number): ReactNode => {
                switch (item?.type) {
                  case 'header':
                    return (
                      <ListGroupItem
                        {...getItemProps({
                          tabIndex: -1,
                          className: 'Dropdown-Header',
                          key: index,
                          ref(node: HTMLElement): void {
                            listRef.current[index] = node;
                          },
                        })}
                        key={index}
                        as={DropdownItemButtonWithRef}
                      >
                        {item?.icon && <span className="Dropdown-ItemPrefix">{item?.icon}</span>}
                        <strong className="Dropdown-ItemLabel">{item?.display || locale(item.label)}</strong>
                        <div className="Dropdown-ItemSuffix">{item?.info && <small className="Dropdown-ItemInfo">{item?.info}</small>}</div>
                      </ListGroupItem>
                    );
                  case 'separator':
                    return (
                      <ListGroupItem
                        {...getItemProps({
                          tabIndex: -1,
                          className: 'Dropdown-Separator',
                          key: index,
                          ref(node: HTMLElement): void {
                            listRef.current[index] = node;
                          },
                        })}
                        key={index}
                        as={DropdownItemButtonWithRef}
                      />
                    );
                  case 'item':
                  default:
                    return (
                      <ListGroupItem
                        {...getItemProps({
                          tabIndex: -1,
                          className: 'Dropdown-Item',
                          key: item.value,
                          ref(node: HTMLElement): void {
                            listRef.current[index] = node;
                          },
                          onClick(): void {
                            setActiveIndex(index);
                            handleChange(item);
                          },
                        })}
                        action
                        active={
                          activeIndex === index || value.includes(item.value) || item?.group?.every((option) => value.includes(option))
                        }
                        disabled={!!disabled || !!item?.disabled || !!loading}
                        key={index}
                        name={item?.value || item?.group?.join?.(',')}
                        as={DropdownItemButtonWithRef}
                      >
                        {item?.icon && <span className="Dropdown-ItemPrefix">{item?.icon}</span>}
                        <span className="Dropdown-ItemLabel">{item?.display || locale(item.label)}</span>
                        <div className="Dropdown-ItemSuffix">
                          {item?.info && <small className="Dropdown-ItemInfo">{item?.info}</small>}
                          {value.includes(item.value) && !item?.disabled && (
                            <span className="Dropdown-ItemCheck">
                              <i className="fa fa-check" />
                            </span>
                          )}
                        </div>
                      </ListGroupItem>
                    );
                }
              })}
            </ListGroup>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </div>
  );
};

const DropdownItemButton = (
  props: { name?: string } & ComponentPropsWithoutRef<'button'>,
  ref: ForwardedRef<HTMLButtonElement>
): ReactNode => <button {...props} ref={ref} />;
const DropdownItemButtonWithRef = forwardRef(DropdownItemButton);

export type DropdownMultiChangeHandler =
  | ((items: string[], item?: DropdownItem) => boolean)
  | ((items: string[], item?: DropdownItem) => void)
  | Dispatch<React.SetStateAction<string[]>>;
export type DropdownMultiProps = Omit<DropdownProps, 'onChange'> & {
  value: string[];
  onChange: DropdownMultiChangeHandler;
  options?: DropdownOptions;
};
const DropdownMulti = (props: DropdownMultiProps): ReactNode => {
  const onChange = (value: string, item: DropdownItem): boolean => {
    let res = undefined;
    if ((value === undefined || value === '') && item?.group === undefined) {
      res = props?.onChange?.([], item);
      return false;
    } else if (value === '*') {
      res = props?.onChange?.(
        props?.value?.length ===
          props?.items?.filter?.((item: DropdownItem): boolean => !item?.disabled && item?.value !== undefined)?.length
          ? []
          : props?.items
              .filter((item: DropdownItem): boolean => !item?.disabled && item?.value !== undefined)
              .map((i: DropdownItem): string => i.value),
        item
      );
    } else if (item?.group?.length > 0) {
      res = props?.onChange?.(
        item?.group?.every?.((option) => props?.value?.includes?.(option))
          ? props?.value?.filter?.((i: string): boolean => !item?.group?.includes?.(i))
          : Array.from(new Set([...(props?.value || []), ...(item?.group || [])])),
        item
      );
    } else {
      res = props?.onChange?.(
        props?.value?.includes?.(value) ? props?.value?.filter?.((i: string): boolean => i !== value) : [...(props?.value || []), value],
        item
      );
    }
    return res ?? true;
  };

  return (
    <Dropdown
      {...props}
      onChange={onChange}
      items={[
        ...(props?.options?.showSelectAll !== false
          ? [
              {
                label:
                  props?.value?.length ===
                  props?.items?.filter?.((item: DropdownItem): boolean => !item?.disabled && item?.value !== undefined)?.length
                    ? 'Deselect All'
                    : 'Select All',
                value: '*',
                icon:
                  props?.value?.length ===
                  props?.items?.filter?.((item: DropdownItem): boolean => !item?.disabled && item?.value !== undefined)?.length ? (
                    <i className="fa fa-times" />
                  ) : (
                    <i className="fa fa-check" />
                  ),
                action: true,
              },
            ]
          : []),
        ...(props?.items || []),
      ]}
    />
  );
};
Dropdown.Multi = DropdownMulti;

const DropdownSticky = (props: DropdownMultiProps): ReactNode => {
  const justOpened = useRef<boolean>(false);
  return (
    <Dropdown.Multi
      {...props}
      options={{
        ...(props?.options || {}),
        onToggle: (isOpen: boolean): void => {
          if (isOpen) justOpened.current = true;
        },
      }}
      onChange={(items: string[], item: DropdownItem): boolean | void => {
        const selected = justOpened.current ? (item?.group ? item?.group : item?.value === '*' ? items : [item?.value]) : items;
        const result = stringify.compare(selected, props?.value || []) ? [] : selected;
        const res = props?.onChange(result, item);
        justOpened.current = false;
        return res ?? true;
      }}
    />
  );
};
Dropdown.Sticky = DropdownSticky;

export default Dropdown;
