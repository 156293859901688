import * as constants from '../constants';

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Datetime } from './dates';
import { Validation } from './validations';
import { getProperty } from './objects';

const { CUSTOM_LABELS, WHITE_SPACES, DATETIME_FORMAT } = constants;

export const unCamelCase = (str: string = ''): string => {
  str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
  str = str.toLowerCase();
  return str;
};
export const upperCase = (str: string): string => str.toUpperCase();
export const lowerCase = (str: string): string => str.toLowerCase();
export const properCase = (str: string): string => lowerCase(str).replace(/^\w|\s\w/g, upperCase);
export const parseAcronyms = (str: string): string => {
  Object.entries(CUSTOM_LABELS).forEach(([key, label]: [string, string]): void => {
    str = str.replace(new RegExp(key, 'gi'), label);
  });
  return str;
};
export const parseLabel = (str: string): string => parseAcronyms(properCase(unCamelCase(str)));
export const uuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string): string => {
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
export const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  // eslint-disable-next-line
  if (bytes == 0) return '0 Bytes';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};
export const slugify = (str: string = '', delimeter: string = '-', trimStr: boolean = true): string => {
  str = replaceAccents(str);
  str = removeNonWord(str);
  str = (trimStr ? trim(str) : str).replace(/ +/g, delimeter).toLowerCase();
  return str;
};
export const replaceAccents = (str: string = ''): string => {
  if (str.search(/[\xC0-\xFF]/g) > -1) {
    str = str
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xDE]/g, 'P')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFE]/g, 'p')
      .replace(/[\xFD\xFF]/g, 'y');
  }
  return str;
};
// eslint-disable-next-line
export const removeNonWord = (str: string = '', replacement: string = ''): string => str.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, replacement);
export const ltrim = (str: string = '', chars: string[] = WHITE_SPACES): string => {
  let start: number = 0;
  const len: number = str.length;
  const charLen: number = chars.length;
  let found: boolean = true;
  let i: number;
  let c: string;
  while (found && start < len) {
    found = false;
    i = -1;
    c = str.charAt(start);
    while (++i < charLen) {
      if (c === chars[i]) {
        found = true;
        start++;
        break;
      }
    }
  }
  return start >= len ? '' : str.substr(start, len);
};
export const rtrim = (str: string = '', chars: string[] = WHITE_SPACES): string => {
  let end: number = str.length - 1;
  const charLen: number = chars.length;
  let found: boolean = true;
  let i: number;
  let c: string;
  while (found && end >= 0) {
    found = false;
    i = -1;
    c = str.charAt(end);
    while (++i < charLen) {
      if (c === chars[i]) {
        found = true;
        end--;
        break;
      }
    }
  }
  return end >= 0 ? str.substring(0, end + 1) : '';
};
export const trim = (str: string = '', chars: string[] = WHITE_SPACES): string => ltrim(rtrim(str, chars), chars);
export const camelCase = (str: string = ''): string => {
  str = replaceAccents(str);
  str = removeNonWord(str)
    .replace(/-/g, ' ')
    .replace(/\s[a-z]/g, upperCase)
    .replace(/\s+/g, '')
    .replace(/^[A-Z]/g, lowerCase);
  return str;
};
export const pascalCase = (str: string = ''): string => camelCase(str).replace(/^[a-z]/, upperCase);
export const sentenceCase = (str: string = ''): string => lowerCase(str).replace(/(^\w)|\.\s+(\w)/gm, upperCase);
export const titleCase = (str: string = ''): string =>
  (str || '').replace(/\w\S*/g, (txt: string): string => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
export const parsePhoneNumber = (phone: string = '', separator: string = ''): string =>
  (phone || '')
    .toString()
    .replace(/\D/g, '')
    .replace(/^(1|)?(\d{3})(\d{3})(\d{4})$/, (input: string, intlCode: string, areaCode: string, first: string, second: string): string =>
      areaCode && first && second ? `${intlCode ? `+${intlCode}` : ''}(${areaCode})${separator}${first}-${second}` : input
    );
export const generateQueryString = (keyVals: any): string =>
  Object.entries(keyVals)
    .filter(([key, val]: [string, string | number]): boolean => !Validation.isNil(key) && !Validation.isNil(val))
    .map(([key, val]: [string, string | number]): string => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
    .join('&');
export const parseQueryString = (str: string = window.location.search): any => {
  const parsedQuery = {};
  const pairs = str.replace(/^\??/, '').split('&');
  for (let i = 0; i < pairs.length; i++) {
    if (pairs[i] === '') continue;
    const pair = pairs[i].split('=');
    parsedQuery[pair[0]] = decodeURIComponent(pair[1]);
  }
  return parsedQuery;
};
export const generateDirectionsLink = (origin: string, destination: string, options: any = {}): string => {
  const request: any = {
    origin,
    destination,
    travelmode: 'driving',
    ...options,
  };
  const query: string = generateQueryString(request);
  return `https://www.google.com/maps/dir/?api=1&${query}`;
};
export const escapeRegExp = (str: string = ''): string => str.replace(/[\\.+*?^$[](){}\/'#\|]/g, '\\$&');
export const tokenRegExp = (start: string, end: string, flags: string = ''): RegExp =>
  new RegExp(`${escapeRegExp(start)}([^${escapeRegExp(end)}]+)?${escapeRegExp(end)}`, flags);
/* replaceTokens
  This method takes a template string and an object of data as its "scope" and returns a string
  with various tokens replaced with the actual data found in the provided data.
  replaceTokens('My name is {{name}}.', {
    name: 'John Doe'
  });
  > "My name is John Doe."
  replaceTokens('My first name is {{name.first}}.', {
    name: {
      first: 'John',
      last: 'Doe'
    }
  });
  > "My first name is John."
*/
export const replaceTokens = (str: string | Function, data: any, open: string = '{{', close: string = '}}'): string => {
  let result: any = str;
  if (typeof str === 'string') {
    result = str.replace(tokenRegExp(open, close, 'gs'), (original: string, token: string): string => {
      let value: string = '';
      if (token) {
        if (typeof data === 'function') {
          try {
            value = data(token);
          } catch (err) {
            console.error(err.message || err);
          }
        } else {
          value = getProperty(token, data, '');
        }
      }
      return value;
    });
  } else if (typeof str === 'function') {
    str = str(data, open, close);
    result = replaceTokens(str, data, open, close);
  }
  return result;
};
export const formatDateTokens = (str: string = "'"): string =>
  replaceTokens(
    str,
    (dateTemplate: string): string => {
      let format: string = DATETIME_FORMAT;
      let date: string = dateTemplate;
      if (!date.match(/::/g)) {
        const [name, dateString] = dateTemplate.split('::');
        format = constants[name] || name;
        date = dateString;
      }
      return new Datetime(date).format(format);
    },
    '{>',
    '<}'
  );
export const formatCurrency = (str: string = ''): string =>
  replaceTokens(str, (amount: string = '0'): string => `$${parseFloat(amount).toFixed(2)}`, '{$', '$}');
export const formatPhoneNumber = (str: string = ''): string =>
  replaceTokens(str, (number: string = ''): string => parsePhoneNumber(number).replace(/\(/g, '').replace(/\)/g, ' '), '{#', '#}');
export const parseTemplateString = (str: string = '', data: any = {}): string => {
  let result: string = replaceTokens(str, data);
  result = formatDateTokens(result);
  result = formatCurrency(result);
  result = formatPhoneNumber(result);
  return getProperty(result, data, result !== str ? result : undefined);
};
export const cleanString = (str: string = ''): string => str.replace(/[^a-z0-9]/gi, '');
export const StringEqual = (a: string[] = [''], b = ''): boolean => {
  const y: string = cleanString(b.toLowerCase().trim());
  let match: boolean = false;
  for (let i = 0; i < a.length; i++) {
    const s = a[i];
    if (typeof s !== 'string' && typeof s !== 'number') continue;
    const x = cleanString(s.toString().toLowerCase().trim());
    if (x.includes(y)) {
      match = true;
      break;
    }
  }
  return match;
};
const cookiePath = '/';
// TODO: Change expiry time to match the backend: 7
export const setCookies = (cookieKeyValues: any): void =>
  Object.entries(cookieKeyValues).forEach(([key, val]: [string, string]): void =>
    Cookies.set(key, val, { expires: 365, path: cookiePath, sameSite: 'strict' })
  );
export const getCookies = (...cookieNames: string[]): Record<string, string> =>
  cookieNames.reduce((acc, curr: string): Record<string, string> => {
    acc[curr] = Cookies.get(curr);
    return acc;
  }, {});
export const deleteCookies = (...cookieNames: string[]): void =>
  cookieNames.forEach((key: string): any => Cookies.remove(key, { path: cookiePath }));
export const getBase64String = (blob: Blob): Promise<string> =>
  new Promise((resolve: any, reject: any): void => {
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (): void => {
        const base64String: string = (reader.result as string).replace('data:', '').replace(/^.+,/, '');
        resolve(base64String);
      };
      reader.readAsDataURL(blob);
    } catch (err) {
      reject(err);
    }
  });
export const getClasses = (...classNames: string[]) =>
  Array.from(new Set(classNames.join(' ').split(/ {1,}/g)))
    .join(' ')
    .trim();
export const parseCoordinates = (str: string): number[] => {
  const match = str.match(/^ {0,}?([\d.\-° NSEW]+)[, ]{1,}?([\d.\-° NSEW]+) {0,}?$/i);
  const [original, lat, lng] = match;
  const output = [lat, lng].map((str: string): number => {
    const result = Math.abs(parseFloat(str)) * (str.match(/w|s|-/gi) ? -1 : 1);
    return result;
  });
  return output;
};
const cryptoKey = 'SKYHOPTECH';
export const encrypt = (string: string): string => CryptoJS.AES.encrypt(string, cryptoKey).toString();
export const decrypt = (string: string): string => CryptoJS.AES.decrypt(string, cryptoKey).toString(CryptoJS.enc.Utf8);
