import { StateStorage, createJSONStorage, persist } from 'zustand/middleware';
import { StoreApi, UseBoundStore, create } from 'zustand';
import { generateQueryString, getResultFromState, parseQueryString } from '@/utils';

type ComponentStateType<T> = {
  state: T;
  setState: (value: T | ((current: T) => T)) => void;
};
type PersistComponentStateType<T> = {
  state: T;
  setState: (value: T | ((current: T) => T)) => void;
  saveState: () => void;
};

export type InitComponentStateType<T> =
  | T
  | ((setState: (value: T | ((current: T) => T)) => void, getState: () => ComponentStateType<T>) => T | [T, Record<string, Function>]);

export const getInitState = <T>(initState: InitComponentStateType<T>, set, get) => {
  const setState = (val: T | ((current: T) => T)): void =>
    set((current: ComponentStateType<T>): ComponentStateType<T> => ({ ...current, state: getResultFromState(val, current.state) }));
  let state: T;
  let methods: Record<string, Function> = {};
  if (typeof initState === 'function') {
    const result = (initState as Function)(setState, get);
    if (Array.isArray(result)) {
      [state, methods] = result;
    } else {
      state = result;
    }
  } else {
    state = initState;
  }
  return {
    state,
    ...methods,
    setState,
  };
};

export const createComponentState = <T>(initState: InitComponentStateType<T>): UseBoundStore<StoreApi<ComponentStateType<T>>> =>
  create<ComponentStateType<T>>((set, get): ComponentStateType<T> => getInitState(initState, set, get));

const customLocalStorage = <T>(initState: T, live: boolean): StateStorage => ({
  getItem: (key: string): string => {
    const value = localStorage.getItem(key);
    if (value === null) return JSON.stringify({ state: { state: initState } });
    return value;
  },
  setItem: (key: string, value: string): void => {
    if (!live) return;
    localStorage.setItem(key, value);
  },
  removeItem: localStorage.removeItem,
});

export type PersistComponentOptions<T> = {
  live?: boolean;
  reviver?: (key: string, value: unknown) => unknown;
  replacer?: (key: string, value: unknown) => unknown;
  merge?: (
    persistedState: Partial<PersistComponentStateType<T>>,
    currentState: PersistComponentStateType<T>
  ) => PersistComponentStateType<T>;
};
export const createPersistentComponentState = <T>(
  name: string,
  initState: InitComponentStateType<T>,
  options?: PersistComponentOptions<T>
) =>
  create<PersistComponentStateType<T>>()(
    persist(
      (set, get) => ({
        ...getInitState(initState, set, get),
        saveState: (): void => {
          const { state } = get();
          const result = JSON.stringify({ state: { state } }, options?.replacer);
          localStorage.setItem(name, result);
        },
      }),
      {
        name,
        storage: createJSONStorage(() => customLocalStorage(initState, options?.live !== false), {
          replacer: options?.replacer, // is a function that is passed to JSON.stringify to serialize the data
          reviver: options?.reviver, // is a function that is passed to JSON.parse to deserialize the data.
        }),
        // zustand does a shallow merge by default since we are nesting the state
        // we need to use a custom default merge function to handle deeply nested values
        merge:
          options?.merge ||
          ((persistedState: Partial<PersistComponentStateType<T>>, currentState) => ({
            ...((persistedState || {}) as Partial<PersistComponentStateType<T>>),
            ...currentState,
            state: {
              ...currentState.state,
              ...((persistedState.state || {}) as T),
            },
          })),
      }
    )
  );

const saveSearchStorage = (state) => {
  const parsed = Object.entries(state).reduce(
    (acc, [key, value]: [string, unknown]) =>
      value === undefined ? { ...acc, [key]: '' } : { ...acc, [key]: Array.isArray(value) ? value.join(',') : value },
    {}
  );
  window.history.pushState(
    {},
    '',
    `${window.location.pathname}?${generateQueryString(parsed)
      .replace(/=undefined/, '')
      .replace(/=&/, '&')
      .replace(/=$/, '')}`
  );
};
const getSearchStorage = (initState) => {
  const parsed = { ...initState, ...parseQueryString(window.location.search) };
  const validKeys = Object.keys(initState);
  const state = Object.entries(parsed).reduce((acc, [key, value]: [string, string]) => {
    if (!validKeys.includes(key)) return acc;
    acc[key] = value === 'undefined' ? '' : value.includes(',') ? value.split(',') : value;
    return acc;
  }, {});
  return JSON.stringify({ state: { state } });
};
const searchStorage = (initState, live: boolean = false): StateStorage => ({
  getItem: (): string => getSearchStorage(initState),
  setItem: (_key: string, value: string): void => {
    if (!live) return;
    const {
      state: { state },
    } = JSON.parse(value);
    saveSearchStorage(state);
  },
  removeItem: (): void => {
    window.history.replaceState({}, '', `${window.location.pathname}`);
  },
});

export type QueryComponentOptions<T> = {
  live?: boolean;
  reviver?: (key: string, value: unknown) => unknown;
  replacer?: (key: string, value: unknown) => unknown;
};
export const createComponentQueryState = <T>(initState: T, options?: QueryComponentOptions<T>) =>
  create<PersistComponentStateType<T>>()(
    persist(
      (set, get) => ({
        ...getInitState(initState, set, get),
        saveState: () => {
          const { state } = get();
          saveSearchStorage(state);
        },
      }),
      {
        name: 'query',
        storage: createJSONStorage(() => searchStorage(initState, options?.live === true), {
          replacer: options?.replacer,
          reviver: options?.reviver,
        }),
      }
    )
  );
