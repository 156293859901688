import './styles.scss';

import { Accordion, Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import { ChatRoomType, useChatState } from '@/hooks/useSocket/chat/useChatRooms';
import React, { FormEvent, HTMLAttributes, MutableRefObject, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { bytesToSize, getBase64String, getClasses, onEnter } from '@/utils';

import ChatMessage from '../../ChatMessage';
import ChatStatus from '../../ChatStatus';
import Field from '@/components/Field';
import Input from '@/components/Input';
import { getUserId } from '@/hooks/useSocket/utils';
import useChatMethods from '@/hooks/useSocket/chat/useChatMethods';

type ChatListContentProps = HTMLAttributes<HTMLDivElement> & {
  roomId?: string;
  roomType?: ChatRoomType;
};

const ChatListContent = ({ roomId, roomType, ...props }: ChatListContentProps) => {
  const [history, setChatState] = useChatState(({ state: { history }, setState }) => [history, setState]);
  const setHistory = useCallback((history: any) => setChatState((state) => ({ ...state, history })), [setChatState]);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const { getHistory } = useChatMethods();
  useEffect(() => {
    setChatState((current) => ({ ...current, roomId }));
    setHistory([]);
    getHistory({
      rooms: [roomId],
      limit: 100,
      offset: 0,
    });
  }, [getHistory, roomId, setChatState, setHistory]);
  const scrollToBottom = useCallback(() => {
    messageEndRef.current.scrollIntoView({ behavior: 'instant' });
  }, [messageEndRef]);
  useEffect(() => {
    if (messageEndRef?.current) scrollToBottom();
  }, [scrollToBottom, history, roomId]);
  return (
    <div {...props} className={getClasses('ChatList-Content', props?.className)}>
      <Accordion.Body className="py-0" onEntered={() => scrollToBottom()}>
        <Container fluid className="d-flex flex-column p-0 {max-height:100%;}">
          <Row className="{align-self:stretch;overflow:auto;min-height:min-content;} flex-grow-1">
            <ChatListContent.Messages messages={history || []} ref={messageEndRef} />
          </Row>
          <Row>
            <ChatListContent.Footer roomId={roomId} roomType={roomType} />
          </Row>
        </Container>
      </Accordion.Body>
    </div>
  );
};

type ChatListContentMessagesProps = HTMLAttributes<HTMLDivElement> & {
  messages: any;
};
const ChatListContentMessages = ({ messages, ...props }: ChatListContentMessagesProps, ref: MutableRefObject<HTMLDivElement>) => {
  return (
    <div {...props} className={getClasses('ChatList-Messages', props?.className)}>
      <div className="d-flex flex-column-reverse">
        {messages?.map?.((message, index) => {
          return <ChatMessage message={message} key={index} />;
        })}
      </div>
      <div ref={ref} />
    </div>
  );
};

type ChatListContentFooterProps = HTMLAttributes<HTMLDivElement> & {
  roomId?: string;
  roomType?: ChatRoomType;
};
const ChatListContentFooter = ({ roomId, roomType, ...props }: ChatListContentFooterProps) => {
  return (
    <div {...props} className={getClasses('ChatList-Footer', props?.className)}>
      <Container fluid>
        <Row>
          <ChatStatus roomId={roomId} />
        </Row>
        <Row>
          <Col className="d-flex gap-2">
            <ChatListContent.Input roomId={roomId} roomType={roomType} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

type ChatListContentChatInputProps = HTMLAttributes<HTMLDivElement> & {
  roomId?: string;
  roomType?: ChatRoomType;
};
const ChatListContentChatInput = ({ roomId, roomType, ...props }: ChatListContentChatInputProps) => {
  const [rooms, setChatState] = useChatState(({ state: { rooms }, setState }) => [rooms[roomType], setState]);
  const { sendMessage, getUsersTyping } = useChatMethods();
  const [files, setFiles] = useState<File[]>([]);
  const userId = getUserId();

  const handleSendImage = useCallback(
    async (files) => {
      for (const file of files) {
        const type = file.type;
        const content = await getBase64String(file);
        sendMessage({
          ...(rooms?.[roomId] && {
            trip_id: rooms[roomId]?.trip?.tripId || null,
            trip_scheduled: rooms[roomId]?.trip?.tripScheduled || null,
            flight_number: rooms[roomId]?.trip?.tripFlightNumber || null,
            servicer_iata_airline_code: rooms[roomId]?.trip?.servicerIataAirlineCode || null,
            room_display_name: rooms[roomId]?.displayName || null,
            room_display_image_url: rooms[roomId]?.displayImageUrl || null,
          }),
          room: roomId,
          content: content,
          content_type: type,
        });
      }
      setFiles([]);
      filesRef.current.value = '';
    },
    [sendMessage, rooms, roomId]
  );
  const deleteImage = (index: number) => {
    if (files) setFiles(files.filter((_, i) => i !== index));
    filesRef.current.value = '';
  };
  const filesRef = useRef(null);
  const [message, setMessage] = useState('');
  const submitMessage = useCallback(async () => {
    // TODO: check that no files are being uploaded
    if (files?.length > 0) await handleSendImage(files);
    if (!message || message.replace(/ {1,}/g, '').length < 3) return;
    const payload = {
      ...(rooms?.[roomId] && {
        trip_id: rooms[roomId]?.trip?.tripId || null,
        trip_scheduled: rooms[roomId]?.trip?.tripScheduled || null,
        trip_flight_number: rooms[roomId]?.trip?.tripFlightNumber || null,
        servicer_iata_airline_code: rooms[roomId]?.trip?.servicerIataAirlineCode || null,
        room_display_name: rooms[roomId]?.displayName || null,
        room_display_image_url: rooms[roomId]?.displayImageUrl || null,
      }),
      room: roomId ? roomId : '',
      content: message,
      content_type: 'text/plain',
    };
    sendMessage(payload);
    setMessage('');
    setChatState((current) => ({
      ...current,
      usersTyping: current.usersTyping.filter((user) => user.room_id !== roomId && user.user_id !== userId),
    }));
  }, [files, handleSendImage, message, rooms, roomId, sendMessage, userId, setChatState]);

  const messageLength = useMemo(() => message?.replace?.(/ {1,}/g, '')?.length, [message]);
  const isValid = useMemo(
    () => (files?.length > 0 && (messageLength === 0 || messageLength >= 3)) || (files?.length === 0 && messageLength >= 3),
    [files, messageLength]
  );
  const isInvalid = useMemo(
    () => (files.length === 0 && messageLength > 0 && messageLength < 3) || (messageLength > 0 && messageLength < 3),
    [files, messageLength]
  );
  const feedback = useMemo(
    () =>
      (files?.length > 0 && (messageLength === 0 || messageLength >= 3)) ||
      (files?.length === 0 && (messageLength === 0 || messageLength >= 3))
        ? undefined
        : 'Must be at least 3 characters or an image',
    [files, messageLength]
  );
  const validity = useMemo(
    () => (isValid === true && isInvalid === false ? true : isInvalid === true && isValid === false ? false : undefined),
    [isValid, isInvalid]
  );

  return (
    <>
      <div className="ChatList-ImageUploadContainer">
        <Button as="div" onClick={() => filesRef.current.click()} className={getClasses('ChatList-ImageUpload', props?.className)}>
          <i className="sv sv-picture"></i>
        </Button>
        <input
          type="file"
          ref={filesRef}
          accept="image/*"
          multiple
          style={{ display: 'none' }}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            const { files } = e.target as HTMLInputElement;
            setFiles(Array.from(files));
          }}
        />
      </div>
      <div {...props} className={getClasses('ChatList-ChatInput', props?.className)}>
        {files?.map?.((file, index) => {
          return (
            <ChatListContent.Image onDelete={deleteImage} display={`${file.name} (${bytesToSize(file.size)})`} index={index} key={index} />
          );
        })}
        <InputGroup>
          <Field valid={validity} feedback={feedback}>
            <Input
              onKeyDown={onEnter(submitMessage)}
              onChange={(value) => {
                getUsersTyping({
                  room_id: roomId,
                  user_id: userId,
                });
                setMessage(value);
              }}
              value={message}
              isValid={isValid}
              isInvalid={isInvalid}
              type="text"
              placeholder="Type a message"
            />
          </Field>
          <Button
            variant="icon"
            onClick={submitMessage}
            className={getClasses('ChatList-ChatSubmit', props?.className)}
            disabled={isInvalid}
          >
            <i className="sv sv-paper-plane" />
          </Button>
        </InputGroup>
      </div>
    </>
  );
};

type ChatListContentImageProps = HTMLAttributes<HTMLDivElement> & {
  display?: string;
  index?: number;
  onDelete?: (index: number) => void;
};
const ChatListContentImage = ({ display, index, onDelete, ...props }: ChatListContentImageProps) => {
  return (
    <InputGroup {...props} className={getClasses('ChatList-Image', props?.className)}>
      <InputGroup.Text className="ChatList-ImageName">{display}</InputGroup.Text>
      <Button onClick={() => onDelete(index)} className="ChatList-ImageDelete">
        <i className="sv sv-trash"></i>
      </Button>
    </InputGroup>
  );
};

type ChatListContentImageNameProps = HTMLAttributes<HTMLButtonElement> & {};
const ChatListContentImageName = ({ ...props }: ChatListContentImageNameProps) => {
  return (
    <InputGroup.Text {...props} className={getClasses('ChatList-ImageName', props?.className)}>
      Image Name
    </InputGroup.Text>
  );
};

type ChatListContentImageDeleteProps = HTMLAttributes<HTMLButtonElement> & {};
const ChatListContentImageDelete = ({ ...props }: ChatListContentImageDeleteProps) => {
  return (
    <Button {...props} onClick={() => alert('Deleting image')} className={getClasses('ChatList-ImageDelete', props?.className)}>
      <i className="sv sv-trash"></i>
    </Button>
  );
};

// Image
ChatListContent.Image = ChatListContentImage;
ChatListContent.ImageName = ChatListContentImageName;
ChatListContent.ImageDelete = ChatListContentImageDelete;

// Input
ChatListContent.Input = ChatListContentChatInput;

// Layout
ChatListContent.Footer = ChatListContentFooter;
ChatListContent.Messages = forwardRef(ChatListContentMessages);
export default ChatListContent;
