import Tooltip from '@/features/Trips/components/Tooltip';

const LocationCell = ({
  name,
  address,
  cityName,
  stateCode,
  zipCode,
  aliases,
}: {
  name: string;
  address: string;
  cityName: string;
  stateCode: string;
  zipCode: string;
  aliases: string[];
}): React.JSX.Element => {
  const street = `${address}, ${cityName}, ${stateCode} ${zipCode}`;
  return (
    <>
      <Tooltip
        content={
          <>
            <div className="text-center">{name}</div>
            <div className="text-center">{street}</div>
          </>
        }
        placement="left"
      >
        <Tooltip content={<small>Click to copy to clipboard.</small>}>
          <button onClick={(): Promise<void> => navigator.clipboard.writeText(`${name}\n${street}`)}>{name || '--'}</button>
        </Tooltip>
      </Tooltip>
      {aliases?.length > 1 && (
        <Tooltip
          content={
            <>
              <div>
                <strong>Aliases:</strong>
                <strong>({aliases?.length})</strong>
                <span> - Click to Copy</span>
              </div>
              <ul className="m-0">{aliases?.map?.((name: string, a: number): JSX.Element => <li key={a}>{name}</li>)}</ul>
            </>
          }
          placement="right"
        >
          <button onClick={(): Promise<void> => navigator.clipboard.writeText(aliases?.join('\n') || '')}>
            <i className="fa fa-circle-info pointer d-print-none ms-2 {opacity:0.4;}" />
          </button>
        </Tooltip>
      )}
    </>
  );
};

export default LocationCell;
