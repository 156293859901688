import ChangePasswordModal from '../ChangePasswordModal';
import ConfirmTripRatesModal from '../../pages/Rates/ConfirmTripRatesModal';
import { Container } from 'react-modal-promise';
import DebugModal from './DebugModal';
import EditCombine from '../../pages/Trips/EditCombine';
import EditCommunication from '../../pages/Trips/EditCommunication';
import EditCompletion from '../../pages/Trips/EditCompletion';
import EditFcr from '../../pages/Trips/EditFcr';
import EditFlag from '../../pages/Trips/EditFlag';
import EditPotentialTripsModal from '../EditPotentialTripsModal';
import EditRatesModal from '../EditRatesModal';
import EditTripsModal from '../EditTripsModal';
import RateReportModal from '../RateReportModal';
import React from 'react';
import TripModal from '@/features/Trips/components/TripModal';

const ModalContainer = (): JSX.Element => {
  return (
    <>
      {/* Start Custom Modals */}
      <ChangePasswordModal type="change" />
      <TripModal />
      <EditTripsModal />
      <EditCompletion />
      <EditFcr />
      <EditCombine />
      <EditFlag />
      <EditCommunication />
      <RateReportModal />
      <EditPotentialTripsModal />
      <EditRatesModal />
      <ConfirmTripRatesModal />
      {/* End Custom Modals */}
      <>
        <DebugModal />
        <Container />
      </>
    </>
  );
};

export default ModalContainer;
