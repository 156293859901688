import './styles.scss';

import React, { useMemo } from 'react';

import DynamicContent from '@/components/DynamicContent';
import { Trip } from '@/models/gen/graphql';
import TripView from '@/features/Trips/components/TripModal/views/trip';
import { createComponentState } from '@/state';

export type TripModalTab = 'trip' | 'flag' | 'communication' | 'completion' | 'fcr' | 'combine' | 'rate';
export type TripModalState = {
  data: Partial<Trip>;
  tab?: TripModalTab;
  selected?: Partial<Trip>[];
  onSubmit?: (state: any) => void;
};
const initState: TripModalState = { data: null, tab: 'trip', selected: [] };
export const useTripModalState = createComponentState(initState);

const TripModal = (): JSX.Element => {
  const state = useTripModalState(({ state }) => state);
  const setState = useTripModalState(({ setState }) => setState);
  const { data, tab, onSubmit, ...rest } = state;
  const mode = data?.id ? 'editing' : 'creating';

  const onClickModalTab = (tabName: TripModalTab) => {
    setState((current) => ({ ...current, tab: tabName }));
  };

  const handleSubmit = (state: any, persist: boolean = false) => {
    onSubmit?.(state);
    if (!persist) onHide();
  };
  const onHide = (): void => {
    setState(initState);
  };

  const View = useMemo(() => {
    const DefaultView = () => <></>;
    switch (tab) {
      case 'trip':
        return TripView;
      default:
        return DefaultView;
    }
  }, [tab]);

  return (
    <DynamicContent
      className="TripModal p-3"
      show={data !== null}
      as="drawer"
      onHide={onHide}
      options={{
        drawer: {
          props: {
            width: 560,
            tabs:
              mode === 'editing'
                ? [
                    {
                      icon: <i className="sv sv-plane" />,
                      tooltip: 'Trip',
                      onClick: () => onClickModalTab('trip'),
                      active: tab === 'trip',
                    },
                    {
                      icon: <i className="sv sv-flag-empty" />,
                      tooltip: 'Flags',
                      onClick: () => onClickModalTab('flag'),
                      active: tab === 'flag',
                    },
                    {
                      icon: <i className="sv sv-message2" />,
                      tooltip: 'Communications',
                      onClick: () => onClickModalTab('communication'),
                      active: tab === 'communication',
                    },
                    {
                      icon: <i className="sv sv-completion" />,
                      tooltip: 'Completion',
                      onClick: () => onClickModalTab('completion'),
                      active: tab === 'completion',
                    },
                    {
                      icon: <i className="sv sv-fcr" />,
                      tooltip: 'Complaints',
                      onClick: () => onClickModalTab('fcr'),
                      active: tab === 'fcr',
                    },
                    {
                      icon: <i className="sv sv-combine-icon" />,
                      tooltip: 'Combine',
                      onClick: () => onClickModalTab('combine'),
                      active: tab === 'combine',
                    },
                    {
                      icon: <i className="sv sv-bag-dollar" />,
                      tooltip: 'Rate',
                      onClick: () => onClickModalTab('rate'),
                      active: tab === 'rate',
                    },
                  ]
                : undefined,
          },
        },
      }}
    >
      {data && <View data={data} mode={data?.id ? 'editing' : 'creating'} onSubmit={handleSubmit} onHide={onHide} {...rest} />}
    </DynamicContent>
  );
};

export default TripModal;
