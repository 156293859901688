import './styles.scss';

import Dropdown, { DropdownItem, DropdownMultiProps, DropdownOptions, DropdownProps } from '@/components/Dropdown';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getClasses, handleError, stringify, titleCase, unCamelCase } from '@/utils';

import { Button } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import useLocalStorage from '@/hooks/useLocalStorage';

export type QueryDropdownProps = Omit<DropdownProps, 'name' | 'items'> & {
  name?: string;
  query: () => Promise<DropdownItem[]>;
  options?: QueryDropdownOptions;
};
export type QueryDropdownOptions = DropdownOptions & {
  ttl?: number;
  showRefreshButton?: boolean;
  lazyLoadItems?: boolean;
};

type ItemStorage = { timestamp: number; items: DropdownItem[] };
// TODO: Type this out more generically and make it a reusable hook. (Not just for DropdownItems)
const useItems = (
  name: string,
  query: () => Promise<DropdownItem[]>,
  options?: QueryDropdownOptions
): [{ items: DropdownItem[]; loading: boolean }, () => Promise<void>] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [{ timestamp = 0, items = [] } = {}, setItems, removeItems] = useLocalStorage<ItemStorage>(name, {
    timestamp: 0,
    items: [],
  });
  const isExpired = useMemo((): boolean => timestamp <= new Date().getTime() - (options?.ttl ?? 1000 * 60 * 5), [timestamp, options?.ttl]);

  const getItems = useCallback(
    async (force: boolean): Promise<void> => {
      if (!force && !isExpired) return;
      try {
        setLoading(true);
        const res = await query();
        setItems((current: ItemStorage): ItemStorage => ({ ...current, items: res || [], timestamp: new Date().getTime() }));
      } catch (err) {
        handleError(err, { notification: { title: unCamelCase(titleCase(name)) } });
      } finally {
        setLoading(false);
      }
    },
    [isExpired, name, query, setItems]
  );

  useEffect((): void => {
    if (options?.lazyLoadItems) return;
    getItems(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): void => {
    if (isExpired) removeItems();
  }, [isExpired, removeItems]);

  return [{ items, loading }, (): Promise<void> => getItems(true)];
};

const QueryDropdown = ({
  name,
  query,
  options: { ttl, lazyLoadItems, showRefreshButton, ...dropdownOptions } = {},
  ...dropdownProps
}: QueryDropdownProps): ReactNode => {
  const [{ items, loading }, refreshItems] = useItems(name, query, { ttl, lazyLoadItems, showRefreshButton });
  const lastItems = useRef<DropdownItem[]>(items);

  useEffect((): void => {
    if (lazyLoadItems || !dropdownProps?.value) return;
    refreshItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  useEffect((): void => {
    if (!items.length || stringify.compare(lastItems.current, items) || !dropdownProps?.value) return;
    lastItems.current = items;
    if (!items.find((i: DropdownItem): boolean => i.value === dropdownProps?.value)) dropdownProps?.onChange?.(undefined, undefined);
  }, [items, dropdownProps]);

  return (
    <Dropdown
      {...dropdownProps}
      className={getClasses('QueryDropdown', dropdownProps?.className)}
      name={name}
      items={items}
      options={{
        ...dropdownOptions,
        loading: loading || dropdownOptions?.loading,
        onToggle: (isOpen: boolean): void => {
          if (isOpen) refreshItems();
        },
        indicators: (
          <>
            {dropdownOptions?.indicators || null}
            {showRefreshButton === true && (
              <Tippy content="Refresh">
                <Button variant="icon" onClick={(): Promise<void> => refreshItems()}>
                  <i className={getClasses('fa fa-refresh', loading ? 'fa-spin' : undefined)} />
                </Button>
              </Tippy>
            )}
          </>
        ),
      }}
    />
  );
};

export type QueryDropdownMultiProps = Omit<DropdownMultiProps, 'name' | 'items'> & Omit<QueryDropdownProps, 'value' | 'onChange'>;

const QueryDropdownMulti = ({ name, query, ...dropdownProps }: QueryDropdownMultiProps): ReactNode => {
  const [{ items, loading }, refreshItems] = useItems(name, query, dropdownProps?.options);

  return (
    <Dropdown.Multi
      {...dropdownProps}
      className={getClasses('QueryDropdownMulti', dropdownProps?.className)}
      name={name}
      items={items}
      options={{
        ...(dropdownProps?.options || {}),
        loading: (loading && items.length === 0) || dropdownProps?.options?.loading,
        onToggle: (isOpen: boolean): void => {
          if (isOpen) refreshItems();
        },
        indicators: (
          <>
            {dropdownProps?.options?.indicators || null}
            {dropdownProps?.options?.showRefreshButton === true && (
              <Tippy content="Refresh">
                <Button variant="icon" onClick={(): Promise<void> => refreshItems()}>
                  <i className={getClasses('fa fa-refresh', loading ? 'fa-spin' : undefined)} />
                </Button>
              </Tippy>
            )}
          </>
        ),
      }}
    />
  );
};
QueryDropdown.Multi = QueryDropdownMulti;

const QueryDropdownSticky = ({ name, query, ...dropdownProps }: QueryDropdownMultiProps): ReactNode => {
  const [{ items, loading }, refreshItems] = useItems(name, query, dropdownProps?.options);

  return (
    <Dropdown.Sticky
      {...dropdownProps}
      className={getClasses('QueryDropdownMulti', dropdownProps?.className)}
      name={name}
      items={items}
      options={{
        ...(dropdownProps?.options || {}),
        loading: (loading && items.length === 0) || dropdownProps?.options?.loading,
        onToggle: (isOpen: boolean): void => {
          if (isOpen) refreshItems();
        },
        indicators: (
          <>
            {dropdownProps?.options?.indicators || null}
            {dropdownProps?.options?.showRefreshButton === true && (
              <Tippy content="Refresh">
                <Button variant="icon" onClick={(): Promise<void> => refreshItems()}>
                  <i className={getClasses('fa fa-refresh', loading ? 'fa-spin' : undefined)} />
                </Button>
              </Tippy>
            )}
          </>
        ),
      }}
    />
  );
};
QueryDropdown.Sticky = QueryDropdownSticky;

export default QueryDropdown;
