import { ReactNode, useCallback, useLayoutEffect, useState } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';

import { Button } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import { Datetime } from '@/utils';
import ImportContractProviderDropdown from '@/components/ImportContractProviderDropdown';
import QueryFilters from '@/components/QueryFilters';
import { createComponentQueryState } from '@/state';

// import Input from '@/components/Input';

export type MessageCenterFilters = {
  startDatetime?: string;
  endDatetime?: string;
  userProviderId?: string;
  flightNumber?: string;
};
export type MessageCenterFiltersProps = { value?: MessageCenterFilters; onSubmit: (values: MessageCenterFilters) => void };

type MessageCenterFiltersState = {
  startDatetime?: string;
  endDatetime?: string;
  userProviderId?: string;
  flightNumber?: string;
};
const initMessageCenterFiltersState: MessageCenterFiltersState = {
  startDatetime: TODAY,
  endDatetime: TODAY_EOD,
  userProviderId: null,
  flightNumber: '',
};

export const useMessageCenterFilters = createComponentQueryState(initMessageCenterFiltersState);

const MessageCenterFilters = ({ onSubmit }: MessageCenterFiltersProps): ReactNode => {
  const [state, setState, saveState] = useMessageCenterFilters(({ state, setState, saveState }) => [state, setState, saveState]);
  const { startDatetime, endDatetime, userProviderId, flightNumber: _flightNumber } = state;
  const [lastState, setLastState] = useState<MessageCenterFiltersState>(state);

  const handleReset = useCallback((): void => {
    const startDatetime = new Datetime().startOf('day').toString();
    const endDatetime = new Datetime().endOf('day').toString();
    setState({ ...initMessageCenterFiltersState, startDatetime, endDatetime });
    setLastState({ ...initMessageCenterFiltersState, startDatetime, endDatetime });
    saveState();
    onSubmit({
      ...initMessageCenterFiltersState,
      userProviderId: null,
      startDatetime,
      endDatetime,
    });
  }, [setState, setLastState, saveState, onSubmit]);
  const handleChange = useCallback(
    (name: string): ((value: string | string[]) => void) =>
      (value: string | string[]): void => {
        setState(
          (current: MessageCenterFiltersState): MessageCenterFiltersState => ({
            ...current,
            [name]: value,
          })
        );
      },
    [setState]
  );
  const handleSubmit = useCallback((): void => {
    saveState();
    setLastState(state);
    onSubmit(state);
  }, [state, saveState, onSubmit]);

  useLayoutEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryFilters>
      {/* TODO: This component needs to be deprecated, and replaced with a new one following our new component models. */}
      <QueryFilters.Control>
        <DateRangePicker
          name="dates"
          value={[startDatetime, endDatetime] as any}
          onChange={(event) => {
            const { value } = event?.target || {};
            const [start, end] = value.split(' - ');
            handleChange('startDatetime')(start);
            handleChange('endDatetime')(end);
          }}
          isDirty={lastState.startDatetime !== startDatetime || lastState.endDatetime !== endDatetime}
        />
      </QueryFilters.Control>
      <QueryFilters.Input>
        <ImportContractProviderDropdown
          value={userProviderId}
          onChange={handleChange('userProviderId')}
          options={{ locale: { 'Select...': 'Airline' } }}
          className={lastState.userProviderId !== userProviderId ? '{border-color:#ff9e16!;}>input' : ''}
        />
      </QueryFilters.Input>
      <QueryFilters.Control>
        <Button variant="success" className="border-white" onClick={handleSubmit}>
          GO
        </Button>
        <Button variant="outline-light" onClick={handleReset}>
          Reset
        </Button>
      </QueryFilters.Control>
      {/* <QueryFilters.Input>
        <Input
          type="number"
          value={flightNumber || ''}
          onChange={handleChange('flightNumber')}
          className={(lastState.flightNumber || '') !== (flightNumber || '') ? '{border-color:#ff9e16!;}>input' : ''}
          placeholder="Flight Number"
        />
      </QueryFilters.Input> */}
    </QueryFilters>
  );
};

export default MessageCenterFilters;
