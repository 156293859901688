import './styles.scss';

import { Button, Card, Col, Collapse, Modal, Row } from 'react-bootstrap';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import FormButton from '../FormButton';
import FormField from '../FormField';
import Logger from '../../utils/logs';
import Tippy from '@tippyjs/react';
import { createPortal } from 'react-dom';
import { decryptObj } from '../../utils/objects';
import { getResultFromState } from '@/utils';
import useForm from '../../hooks/useForm';
import useHotkeys from '../../hooks/useHotkeys';
import { useNavigate } from 'react-router-dom';
import useOnChange from '@/hooks/useOnChange';
import { useSearchParams } from 'react-router-dom';
import useSettings from '@/state/settings';

const log = Logger.of('Filters');
type FilterSegmentProps = {
  values: any;
  onChange: any;
  setFilters: any;
  expanded: boolean;
};
export type FiltersProps = {
  name?: string;
  primary?: (props: FilterSegmentProps) => JSX.Element;
  alternate?: (props: FilterSegmentProps) => JSX.Element;
  secondary?: (props: FilterSegmentProps) => JSX.Element;
  controls?: (props: FilterSegmentProps) => JSX.Element;
  actions?: React.ReactNode;
  onSubmit: (filters: any) => void;
  onReset?: (filters?: any) => any;
  isValid?: (filters: any) => boolean;
  value?: any;
  submitOnMount?: boolean;
};
type QueryParams = {
  [key: string]: string | string[];
};
type StoredFiltersType = { [key: string]: any; persist?: boolean };

const Filters = ({
  name,
  primary: PrimaryFilters,
  secondary: SecondaryFilters,
  alternate: AlternateFilters,
  controls: ControlFilters,
  actions,
  onSubmit,
  onReset,
  isValid,
  value,
  submitOnMount = false,
}: FiltersProps): JSX.Element => {
  const [state, setState] = useState({
    // TODO: When we want this feature on ALL pages, remove the conditional and make the default true.
    expanded: name === 'tripFilters' ? true : false,
    openSettings: false,
    loading: false,
  });
  const [{ rememberFilters, savedFilters }, setSettings] = useSettings(
    ({
      state: {
        filters: { [name]: { remember: rememberFilters = false, saved: savedFilters = {} } = {} },
      },
      setState,
    }) => [{ rememberFilters, savedFilters }, setState]
  );
  const { expanded, openSettings, loading } = state;
  const [localFilters, , setFilters] = useForm(value || {});
  const filters = useMemo(
    (): StoredFiltersType => (rememberFilters ? savedFilters : localFilters),
    [rememberFilters, savedFilters, localFilters]
  );
  const goBtnRef = useRef<HTMLButtonElement>(null);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const renderCount = useRef(0);

  useHotkeys('Filters', {
    'shift+enter': {
      name: 'Go Button',
      description: 'Submits Search',
      action: (): void => {
        if (goBtnRef.current) goBtnRef.current.click();
        else log.warn('hotkey: go btn ref is undefined');
      },
    },
  });
  const setFilterState = (val) => {
    let updated = getResultFromState(val, localFilters || {});
    setSettings((current) => {
      updated = getResultFromState(val, (current.filters?.[name] || {})?.saved || {});
      return {
        ...current,
        filters: {
          ...current.filters,
          [name]: {
            remember: false,
            ...(current.filters?.[name] || {}),
            saved: updated,
          },
        },
      };
    });
    setFilters(val);
  };

  const onChange = useOnChange(setFilterState, localFilters);

  const handleSubmit = async (input: any = filters): Promise<void> => {
    try {
      if (!!isValid && !isValid(input)) return;
      setState((current: any): any => ({ ...current, loading: true }));
      await onSubmit(input);
    } catch (err) {
      console.error(err?.message || err);
    } finally {
      setState((current: any): any => ({ ...current, loading: false }));
    }
  };
  const handleReset = async (): Promise<void> => {
    let originalFilters: any = onChange.reset();
    if (onReset) originalFilters = onReset(originalFilters) || originalFilters;
    setFilterState((current: any): any => {
      return { ...(originalFilters || {}), persist: !!current?.persist };
    });
    navigate('?', { replace: true });
    await handleSubmit(originalFilters);
  };
  const isDisabled = useMemo((): boolean => {
    if (!isValid) return false;
    return !isValid(filters);
  }, [filters, isValid]);

  const getQueryParams = (): QueryParams => {
    if (params.get('hash')) {
      try {
        const result = decryptObj(params.get('hash'));
        setFilterState((current: any): any => ({ ...current, ...result }));
        return result as QueryParams;
      } catch (err) {
        console.error('Failed to decrypt object', err);
      }
    }
    if (params.toString().length === 0) return;
    const queryParams: QueryParams = {};
    for (const [key, value] of params.entries()) {
      if (key === 'debug') continue;
      queryParams[key] = value.includes(',') ? value.split(',') : value;
    }
    setFilterState((current: any): any => ({ ...current, ...queryParams }));
    return queryParams;
  };
  useEffect((): void => {
    const params = getQueryParams();
    if (submitOnMount) {
      const result = rememberFilters ? { ...localFilters, ...savedFilters, ...params } : { ...localFilters, ...params };
      handleSubmit(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const lastRememberFiltersValue = useRef<boolean>(null);
  useEffect(() => {
    if (!rememberFilters || lastRememberFiltersValue.current === null) {
      lastRememberFiltersValue.current = rememberFilters;
      return;
    }
    setFilterState(localFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rememberFilters]);

  const Result = (
    <>
      <Card className="Filters rounded-0" key={renderCount.current}>
        <Card.Body className="d-flex flex-column py-2 gap-2">
          <Row className="d-flex flex-nowrap gap-4">
            <Col className="d-flex flex-nowrap gap-2">
              {PrimaryFilters && <PrimaryFilters values={filters} onChange={onChange} setFilters={setFilterState} expanded={expanded} />}
              {SecondaryFilters && (
                <Button
                  name="EXPAND_MORE"
                  variant="outline-gray"
                  onClick={(): void => setState((current: any): any => ({ ...current, expanded: !current?.expanded }))}
                >
                  {!expanded ? 'More' : 'Less'}
                </Button>
              )}
              {!!(PrimaryFilters || SecondaryFilters || AlternateFilters) && (
                <>
                  <Button
                    name="SUBMIT_SEARCH"
                    variant="success"
                    onClick={(): Promise<void> => handleSubmit(filters)}
                    disabled={!!loading || isDisabled}
                    ref={goBtnRef}
                  >
                    {(loading && <i className="fa fa-spinner fa-pulse" />) || 'Go'}
                  </Button>
                  <FormButton
                    name="RESET_SEARCH"
                    className="{margin:0!;}_span"
                    icon={<i className="sv sv-refresh {font-size:1.5rem;} {display:none;}@>=1400" />}
                    variant="outline-gray"
                    onClick={handleReset}
                  >
                    Reset
                  </FormButton>
                </>
              )}
              {ControlFilters && <ControlFilters values={filters} onChange={onChange} setFilters={setFilterState} expanded={expanded} />}
              <div className="flex-grow-1" />
              {AlternateFilters && (
                <AlternateFilters values={filters} onChange={onChange} setFilters={setFilterState} expanded={expanded} />
              )}
              {name && !!(PrimaryFilters || SecondaryFilters || AlternateFilters) && (
                <>
                  <Tippy content="Filter Settings">
                    <Button
                      variant={`${!openSettings ? 'outline-' : ''}gray`}
                      onClick={(): void => setState((current: any): any => ({ ...current, openSettings: !current?.openSettings }))}
                    >
                      <i className="sv sv-cog {font-size:1.5rem;}" />
                    </Button>
                  </Tippy>
                  {actions}
                </>
              )}
            </Col>
          </Row>
          {SecondaryFilters && (
            <Collapse in={expanded}>
              <Row>
                <Col className="d-flex flex-nowrap gap-2">
                  <SecondaryFilters values={filters} onChange={onChange} setFilters={setFilterState} expanded={expanded} />
                </Col>
              </Row>
            </Collapse>
          )}
        </Card.Body>
      </Card>
      {name && (
        <PageSettingsModal
          show={openSettings}
          onHide={(): void => setState((current: any): any => ({ ...current, openSettings: false }))}
          name={name}
        />
      )}
    </>
  );

  if (document.getElementById('PageFilters')) return createPortal(Result, document.getElementById('PageFilters'));
  return Result;
};

export type PageSettingsModalProps = {
  show: boolean;
  onHide: () => void;
  name: string;
};
export const PageSettingsModal = ({ show, onHide, name }: PageSettingsModalProps): ReactNode => {
  const [{ rememberFilters, rememberColumns, rememberHeadings, rememberDetails }, setSettings] = useSettings(
    ({
      state: {
        filters: { [name]: { remember: rememberFilters = false } = {} },
        columns: { [name]: { remember: rememberColumns = false } = {} },
        headings: { [name]: { remember: rememberHeadings = false } = {} },
        details: { [name]: { remember: rememberDetails = false } = {} },
      },
      setState,
    }) => [{ rememberFilters, rememberColumns, rememberHeadings, rememberDetails }, setState]
  );

  const createToggle = useCallback(
    (key) =>
      setSettings((current) => ({
        ...current,
        [key]: {
          ...(current?.[key] || {}),
          [name]: {
            remember: !current?.[key]?.[name]?.remember,
            saved: !current?.[key]?.[name]?.remember === false ? {} : current?.[key]?.[name]?.saved || {},
          },
        },
      })),
    [name, setSettings]
  );
  const onChangeRememberFilters = useCallback(() => createToggle('filters'), [createToggle]);
  const onChangeRememberDetails = useCallback(() => createToggle('details'), [createToggle]);
  const onChangeRememberHeadings = useCallback(() => createToggle('headings'), [createToggle]);

  return (
    <Modal
      className="{padding:1rem!;}_.modal-content"
      show={true}
      onHide={onHide}
      size="sm"
      centered
      backdrop={show}
      style={{ visibility: show ? 'visible' : 'hidden' }}
    >
      <div id="PageSettings" className={name}>
        <Modal.Body className="general" title="General" />
        <Modal.Body className="filters static" title="Filters" />
        <Modal.Body className="group-controls">
          <FormField
            name="persist"
            onChange={onChangeRememberFilters}
            checked={!!rememberFilters}
            type="checkbox"
            label="Remember"
            condensed
          />
        </Modal.Body>
        <Modal.Body className="columns" title="Columns" />
        <Modal.Body className="report-details" title="Report Details" />
        <Modal.Body className="group-controls">
          <FormField
            name="persistDetails"
            onChange={onChangeRememberDetails}
            checked={!!rememberDetails}
            type="checkbox"
            label="Remember"
            condensed
          />
        </Modal.Body>
        <Modal.Body className="report-heading" title="Report Heading" />
        <Modal.Body className="group-controls">
          <FormField
            name="persistHeading"
            onChange={onChangeRememberHeadings}
            checked={!!rememberHeadings}
            type="checkbox"
            label="Remember"
            condensed
          />
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button variant="outline-gray" onClick={onHide}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(Filters);
