import { Rate, Trip, UpdateRateOnTripResponse } from '@/models/gen/graphql';
import { Validation, handleError, onEnter } from '@/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useTripTableState, { TripRowKeyEnum, TripTableState } from '@/features/Trips/components/TripsTable/hook';

import { Button } from 'react-bootstrap';
import Input from '../../../../../components/Input';
import LoadingSpinner from '@/components/LoadingSpinner';
import React from 'react';
import Tooltip from '@/features/Trips/components/Tooltip';
import { TripsTableRowEventHandlers } from '@/features/Trips/components/TripsTable/TripTableRow';
import updateRateOnTrips from '@/api/services/rates/updateRateOnTrips';
import useDelayCallback from '@/hooks/useDelayCallback';
import { useGetCorrectRatesForTrips } from '@/api/services/rates/getCorrectRatesForTrips';
import { useRunRateAmountOnTrips } from '@/api/services/trips/runRateAmountOnTrips';

const RateCell = ({
  rate,
  rowId,
  onSetRow,
  onEditRateReport,
}: {
  rate: number;
  rowId: string;
  onSetRow: TripTableState['onSetRow'];
  onEditRateReport: TripsTableRowEventHandlers['onEditRateReport'];
}): React.JSX.Element => {
  const selected = useTripTableState(({ state }) => state.selected);
  const [editing, setEditing] = useState(false);

  const dedupeSelected = useMemo((): string[] => Array.from(new Set([...Array.from(selected.keys()), rowId])), [selected, rowId]);
  const onDoubleClick = (): void => console.log('double clicked row', rowId);

  return (
    <div className="d-flex justify-between align-items-center ge-2 flex-nowrap" onDoubleClick={onDoubleClick}>
      {!editing && <SuggestRate rowId={rowId} selected={dedupeSelected} onSetRow={onSetRow} onEditRateReport={onEditRateReport} />}
      <InlineEditRate rate={rate} rowId={rowId} selected={dedupeSelected} onSetRow={onSetRow} editing={editing} setEditing={setEditing} />
    </div>
  );
};

const DisplayRate = ({ rate }: { rate: number }): React.JSX.Element => {
  const parsed = parseFloat(`${rate}`) || 0;
  const formattedWholeNumber = parseInt(`${rate}`.split('.')[0]).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <>
      {rate % 1 === 0 ? (
        parsed
      ) : (
        <>
          {formattedWholeNumber}
          <sup>{parsed.toFixed(2).split('.')[1]}</sup>
        </>
      )}
    </>
  );
};

type InlineEditRateProps = {
  rowId: string;
  rate: number;
  selected: string[];
  onSetRow: TripTableState['onSetRow'];
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};
const InlineEditRate = ({ rate, rowId, selected, onSetRow, editing, setEditing }: InlineEditRateProps): React.JSX.Element => {
  const [{ loading }, { fetch: runRateAmountOnTrips }] = useRunRateAmountOnTrips();
  const [value, setValue] = useState('');

  const onSubmitInlineRate = async (value: string): Promise<void> => {
    try {
      const amount = parseFloat(`${value}`);
      if (!Validation.isNumber(amount)) throw new Error('invalid amount');
      setEditing(false);
      // format bulk rate
      const payload = selected.map((tripId: string): { tripId: string; amount: number } => ({
        tripId,
        amount,
      }));
      const res = await runRateAmountOnTrips(payload);
      if (!res.length) throw new Error('failed to update rate');
      const { rateId, rateGroupId } = res[0];
      const updates: Partial<Trip> = {
        id: rowId,
        rate: {
          id: rateId,
          rateGroupId: rateGroupId,
          rate: amount,
        } as Rate,
      };

      const tripIds = res.map((a): string => a.tripId);

      onSetRow(updates, tripIds, TripRowKeyEnum.SYNC_ROW_IDS);
      setEditing(false);
    } catch (err) {
      handleError(err, { notification: { title: 'Update Trip Rate' } });
    }
  };

  const handleChange = (input: string): void => {
    if (input.includes('.')) {
      const [whole, decimal] = input.split('.');
      if (decimal.length > 2) return;
      input = `${whole}.${decimal.slice(0, 2)}`;
    }

    let parsed = parseFloat(input);
    if (!input || !Validation.isNumber(parsed) || parsed < 0) parsed = 0;
    setValue(parsed.toString());
  };

  useEffect((): void => {
    setValue(`${rate ?? ''}`);
  }, [rate]);

  return (
    <>
      {editing && (
        <Input
          name="rate"
          type="number"
          className="InputCell"
          onBlur={(): void => setEditing(false)}
          value={value || ''}
          placeholder="--"
          onChange={handleChange}
          onKeyDown={onEnter(({ target }: { target: HTMLInputElement }) => onSubmitInlineRate(target.value))}
          onFocus={({ target }: { target: HTMLInputElement }): void => {
            target.select();
            target.scrollLeft = 0;
          }}
          autoFocus
        />
      )}
      {!loading && !editing && (
        <Button className="px-0" variant="icon" onClick={(): void => setEditing(true)}>
          {Validation.isNumber(rate) ? <DisplayRate rate={rate} /> : '--'}
        </Button>
      )}
      {loading && !editing && (
        <span>
          <LoadingSpinner size="sm" />
        </span>
      )}
    </>
  );
};

const SuggestRate = ({
  rowId,
  selected,
  onSetRow,
  onEditRateReport,
}: {
  rowId: string;
  selected: string[];
  onSetRow: TripTableState['onSetRow'];
  onEditRateReport: TripsTableRowEventHandlers['onEditRateReport'];
}): React.JSX.Element => {
  const [{ data = [], loading }, { fetch: getCorrectRatesForTrips }] = useGetCorrectRatesForTrips();
  const [called, setCalled] = useState(false);

  const suggestedRate = data[0]?.newRate;
  const displayRateReport = selected?.length > 1 && selected?.includes(rowId);

  const applyRateSuggestion = useCallback(async (): Promise<UpdateRateOnTripResponse[]> => {
    try {
      const res = await updateRateOnTrips([{ rateHistoryId: suggestedRate?.id, tripId: rowId }]);
      if (!res.length) throw new Error('failed to update rate');
      const updates: Partial<Trip> = {
        id: rowId,
        rate: {
          id: suggestedRate?.id,
          rateGroupId: suggestedRate?.rateGroupId,
          rate: suggestedRate?.rate,
        } as Rate,
      };

      const tripIds = res.map((a): string => a.tripId);

      onSetRow(updates, tripIds, TripRowKeyEnum.SYNC_ROW_IDS);
      return res;
    } catch (err) {
      handleError(err, { notification: { title: 'Update Trip Rate' } });
    }
  }, [suggestedRate?.id, suggestedRate?.rateGroupId, suggestedRate?.rate, rowId, onSetRow]);

  const [handleMouseEnter, handleMouseLeave] = useDelayCallback((): void => {
    if (displayRateReport) return;
    setCalled(true);
    getCorrectRatesForTrips([rowId]);
  }, 250);

  useEffect((): (() => void) => {
    return (): void => setCalled(false);
  }, []);

  return (
    <Tooltip
      placement="left"
      interactive={!!(displayRateReport || suggestedRate)}
      content={
        <div className="d-flex gap-2">
          {displayRateReport && (
            <Button size="sm" onClick={(): void => onEditRateReport(selected)} className="{white-space:nowrap}">
              Rate Report
            </Button>
          )}
          {/* display loading spinner if the request is not called */}
          {(!called || loading) && !displayRateReport && <LoadingSpinner size="sm" />}
          {called && !loading && !displayRateReport && !suggestedRate && (
            <span className="{white-space:nowrap;line-height:2rem;}">No Suggested Rate</span>
          )}
          {called && !loading && !displayRateReport && suggestedRate && (
            <>
              <span className="{white-space:nowrap;line-height:2rem;}">{`Suggested ${suggestedRate.type} $${suggestedRate.rate.toFixed(2)}`}</span>
              <Button size="sm" onClick={applyRateSuggestion}>
                Apply
              </Button>
            </>
          )}
        </div>
      }
    >
      <i
        className="fa fa-circle-info pointer pe-2 d-print-none {opacity:0.4;}"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Tooltip>
  );
};

export default React.memo(RateCell);
