import 'intersection-observer';
import './theme.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'tippy.js/dist/tippy.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import './App.scss';

import { BrowserRouter, Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import React, { ReactNode } from 'react';
import store, { persistor } from './store';

import Airlines from './pages/Airlines';
import ApolloRoot from './components/ApolloRoot';
import Availability from './pages/Availability';
import ChangePasswordModal from './components/ChangePasswordModal';
import Clients from './pages/Clients';
import ContractedRates from './pages/ContractedRates';
import { ErrorBoundary } from '@highlight-run/react';
import Fcrs from './pages/Fcrs';
import HighlightProvider from './components/HighlightProvider';
import HtmlTripPage from '@/features/Trips/new';
import ImportHistory from './pages/Manifests/ImportHistory';
import InvoiceHistory from './pages/InvoiceHistory';
import InvoicePreview from './pages/InvoicePreview';
import Locations from './pages/Locations';
import Login from './pages/Login';
import ManifestPreview from './pages/Manifests/ManifestPreview';
import Manifests from './pages/Manifests';
import MessageCenter from '@/features/MessageCenter';
import NotFound from './pages/NotFound';
import { PersistGate } from 'redux-persist/integration/react';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProtectedRoute from './components/ProtectedRoute';
import PunchPolicy from './pages/PunchPolicy';
import Rates from './pages/Rates';
import Ratings from './pages/Ratings';
import { Provider as ReduxProvider } from 'react-redux';
import ReportProblem from './pages/ReportProblem';
import Reports from './pages/Reports';
import ResetPassword from './pages/ResetPassword';
import Roles from './pages/Roles';
import Sandbox from './pages/Sandbox';
import Settings from '@/features/settings';
import Snapshots from './pages/Reports/Snapshots';
import { SocketProvider } from '@/hooks/useSocket/context';
import { Toaster } from 'sonner';
import Trips from './pages/Trips';
import Users from './pages/Users';
import Vehicles from './pages/Vehicles';
import config from './config.json';
import { pascalCase } from '@/utils';

/* Routes
  Populating the routes dynamically from here keeps management of the router simple.
  The following Object is looped over, with the Key being the path of the Route, and
  the value providing the Page/Component that is to be rendered.

  The NotFound page is not included here because it has to be the last Route provided.

  UPDATE: 3/21/2024
  - Added createRoute to dynamically create routes with or without the ProtectedRoute wrapper.
  - Only the routes that have been updated to no longer provide their own wrapper have been updated to use this.
*/
const createRoute =
  (title: string, RenderRoute: (props?: RouteProps) => ReactNode, open: boolean = false) =>
  // eslint-disable-next-line react/display-name
  (props: RouteProps): ReactNode =>
    !open ? (
      <ProtectedRoute title={title} className={pascalCase(title)}>
        <RenderRoute {...props} />
      </ProtectedRoute>
    ) : (
      <RenderRoute {...props} />
    );

const routes: Record<string, (props: RouteProps) => ReactNode> = {
  // Public Routes
  '/login': createRoute('Login', Login, true),
  // Protected Routes
  // TODO: Update all routes to use createRoute.
  '/': createRoute('Trips', HtmlTripPage),
  '/availability': Availability,
  '/message-center': MessageCenter,
  '/clients': createRoute('Clients', Clients),
  '/airlines': createRoute('Airlines', Airlines),
  '/contract-rates': createRoute('Contract Rates Report', ContractedRates),
  '/complaints': createRoute('Complaints', Fcrs),
  '/imports/history': ImportHistory,
  '/invoices': InvoiceHistory,
  '/invoices/:id': createRoute('Invoice Preview', InvoicePreview),
  '/locations': Locations,
  '/manifests': createRoute('Manifests', Manifests),
  '/manifests/:id': createRoute('Manifest Preview', ManifestPreview),
  '/permissions': Roles,
  '/privacy-policy': PrivacyPolicy,
  '/punch-policy': createRoute('Punch Policy', PunchPolicy),
  '/rates': Rates,
  '/ratings': createRoute('Ratings', Ratings),
  '/report': ReportProblem,
  '/reports': Reports,
  '/reports/:type': Reports,
  '/reports/snapshots/:id': Snapshots,
  '/reset-password': ResetPassword,
  '/sandbox/:route': Sandbox,
  '/trips': createRoute('Trips', HtmlTripPage),
  '/users': Users,
  '/vehicles': Vehicles,
  '/settings': createRoute('Settings', Settings),
  '/settings/:sub-route': createRoute('Settings', Settings),
};

/* Config
  Get the current config.json, and set its values according to the current host.
*/
const env = Object.entries(config.env || {}).reduce(
  (acc: string, [name = '', domains = []]: [string, string[]]): string => (domains.includes(window.location.host) ? name : acc),
  'local'
);
const configEnv = (config.env || {})?.[env] || [];
const hostDomains = Array.isArray(configEnv) ? configEnv : [configEnv];
window['__config__'] = Object.freeze({
  ...(window?.['__config__'] || {}),
  ...config,
  env,
  'client-api-url': config['client-api-url'].replace(/{{env}}/g, env),
  'chat-api-url': config['chat-api-url'].replace(/{{env}}/g, env),
  envDomains: hostDomains,
});

const App = (): ReactNode => (
  <SocketProvider>
    <ApolloRoot>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <HighlightProvider>
            <ErrorBoundary>
              <Toaster
                expand={false}
                richColors
                position="top-right"
                closeButton
                pauseWhenPageIsHidden
                offset={0}
                toastOptions={{
                  classNames: {
                    closeButton: 'Toast-Close',
                    actionButton: 'Toast-Action',
                  },
                }}
                duration={4000}
              />
              <BrowserRouter>
                <Routes>
                  {Object.entries(routes).map(
                    ([path, Page]: [string, (props: RouteProps) => ReactNode], index: number): ReactNode => (
                      <Route path={path} element={<Page />} key={`${path}_${index}`} />
                    )
                  )}
                  <Route path={'/trips/v4'} element={<Navigate to={'/trips'} replace={true} />} />
                  <Route path={'*'} element={<NotFound />} />
                </Routes>
              </BrowserRouter>
              <div className="Drawers" />
              <ChangePasswordModal type="reset" />
            </ErrorBoundary>
          </HighlightProvider>
        </PersistGate>
      </ReduxProvider>
    </ApolloRoot>
  </SocketProvider>
);

export default App;
export { routes };
