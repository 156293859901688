import {
  AirportHasLocation,
  Location,
  LocationMappingInput,
  OverrideImportInput,
  OverrideLocation,
  OverrideOptions,
} from '../../models/gen/graphql';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';

import EditLocationModal from '../EditLocationModal';
import EditModal from '../EditModal/new';
import Logger from '../../utils/logs';
import ManifestFileUpload from '../../pages/Manifests/ManifestFileUpload';
import SelectClient from '../SelectClient';
import SelectImport from '../SelectImport';
import { createLocationWithPickupPoint } from '../../api/services/locations';
import runOverrideImports from '../../api/services/imports/runOverrideImports';
import useForm from '../../hooks/useForm';
import { useGetOverrideImportOptions } from '../../api/services/imports/getOverrideImportOptions';
import useUuid from '../../hooks/useUuid';

const log = Logger.of('ManifestOverridesModal');
type ManifestOverridesModalState = {
  importName: string;
  locationMapping: OverrideLocation[];
  createdLocations: [];
  tolerance: number;
  addingLocation: Partial<Location>;
  socialDistance: boolean;
  // showBuffers: boolean;
  filename: string;
  previousImportId: string;
};

const initManifestOverridesModalState: ManifestOverridesModalState = {
  importName: '',
  locationMapping: [],
  createdLocations: [],
  tolerance: 0,
  socialDistance: false,
  addingLocation: undefined,
  // showBuffers: undefined,
  filename: undefined,
  previousImportId: undefined,
};
const ManifestOverridesModal = ({ show, onHide, onSubmit, importId, name: importName }) => {
  const [state, onChange, setState] = useForm<ManifestOverridesModalState>(initManifestOverridesModalState);
  const {
    createdLocations,
    addingLocation,
    /* showBuffers, */
    filename,
    previousImportId,
    locationMapping,
    socialDistance,
    tolerance,
  } = state;

  const [{ data, loading }, { fetch: getOverrideImportOptions, refetch }] = useGetOverrideImportOptions();
  const [{ allowOverrideTolerance = false, missingLocations = [], airportCodes = [], inaccessibleMarkets = [] } = {}] = data || [];

  const uuid = useUuid();

  const handleHide = () => {
    setState(initManifestOverridesModalState);
    onHide();
  };

  const handleSubmit = async (...args) => {
    const input: OverrideImportInput = {
      importId,
      locationMapping: (locationMapping || []).map(
        ({ locationId, clientId: providerId }: OverrideLocation): LocationMappingInput => ({ locationId, providerId })
      ),
      previousImportId,
      socialDistance,
      tolerance,
    };
    await runOverrideImports([input]);
    onSubmit(importId);
  };

  const handleGetOverrideImportOptions = async (id: string, refresh: boolean = false): Promise<OverrideOptions> => {
    try {
      const res = await (refresh ? refetch : getOverrideImportOptions)([id]);
      const overrideOptions = res?.[0];
      if (!overrideOptions) throw new Error('No res calling getOverrideImportOptions');

      setState(
        (current: ManifestOverridesModalState): ManifestOverridesModalState => ({
          ...current,
          locationMapping: overrideOptions?.locationMapping || [],
          socialDistance: overrideOptions?.socialDistancing || false,
          tolerance: overrideOptions?.tolerance || 0,
          addingLocation: undefined,
        })
      );

      return overrideOptions;
    } catch (e) {
      log.error('handleGetOverrideImportOptions', e);
    }
  };
  const onCreateLocation = async (locationObj) => {
    await createLocationWithPickupPoint(locationObj);
    await handleGetOverrideImportOptions(importId, true);
  };
  const onAddLocation = (locationDisplayName: string) => {
    try {
      if (!locationDisplayName) throw new Error('No location name provided');
      const input: Partial<Location> = {
        airports: (airportCodes || []).map((airportCode: string): AirportHasLocation => ({ airportCode, locationId: undefined })),
        aliases: [{ name: locationDisplayName, id: undefined, locationId: undefined }],
      };
      const [name, ...addressParts] = (locationDisplayName || '').split(/ {0,}, {0,}/g);
      input.name = name;
      input.address = addressParts.join(', ') || '';

      setState(
        (current: ManifestOverridesModalState): ManifestOverridesModalState => ({
          ...current,
          addingLocation: input,
        })
      );
    } catch (e) {
      log.error('onAddLocation', e);
    }
  };

  useEffect(() => {
    if (!importId) return;
    handleGetOverrideImportOptions(importId);
  }, [importId, show]);

  const isNotValid = missingLocations.length > createdLocations.length;

  return (
    <>
      <EditModal
        name="manifestOverridesModal"
        title="Upload"
        size="lg"
        show={show}
        loading={loading}
        onHide={handleHide}
        options={{
          Footer: null,
        }}
      >
        {show?.providerId && <ManifestFileUpload name={filename} provider={show?.providerId} progress={100} disabled />}

        <div className="d-flex w-100">
          <div className="w-75 me-5">
            <div className="m-0 mb-3 pb-3 border-bottom">
              <span className="fw-semibold">Airport Checking: </span>
              <span className={inaccessibleMarkets.length ? 'text-danger' : 'text-success'}>
                {inaccessibleMarkets.length ? `Missing Airports - ${inaccessibleMarkets.join(', ')}` : 'All Airports Found'}
              </span>
              {!!inaccessibleMarkets.length && <span className="ps-3">&#40;Please contact admin&#41;</span>}
            </div>
            <div className="d-flex w-100 align-items-center">
              <div className="d-flex align-items-center justify-content-between w-50 border-end pe-3">
                <Form.Label htmlFor={uuid} className="fw-semibold">
                  Split schedule using social <br /> distancing guidelines:
                </Form.Label>
                <Form.Switch
                  id={uuid}
                  name="socialDistance"
                  className="ActiveSwitch form-switch-reverse"
                  checked={socialDistance}
                  onChange={onChange.toggle}
                />
              </div>
              <div className="d-flex align-items-center justify-content-center w-50 m-0 ms-2">
                <div className="pe-3">Backdate Minutes:</div>
                <Form.Control
                  className="text-center w-50"
                  type="number"
                  size="sm"
                  name="tolerance"
                  min={0}
                  onChange={onChange.int}
                  value={tolerance}
                  disabled={!allowOverrideTolerance}
                />
              </div>
            </div>
          </div>
          <div className="w-25">
            <SelectImport
              name="previousImportId"
              placeholder={importName || 'Select a previous import'} // importName normally is unavailable.
              value={previousImportId}
              onChange={onChange}
              providerId={show?.provider?.id}
              importId={importId}
            />
          </div>
        </div>

        {(locationMapping || []).map(
          (location: OverrideLocation, l: number): React.JSX.Element => (
            <div className="d-flex w-100 mb-3 pb-3 border-bottom align-items-center" key={l}>
              <div className="w-75">{location.locationDisplayName}</div>
              <div className="w-25">
                <SelectClient
                  name={`locationMapping.${l}.clientId`}
                  placeholder="Select Client"
                  onChange={onChange}
                  value={location.clientId}
                />
              </div>
            </div>
          )
        )}
        {missingLocations.map(
          (locationDisplayName: string, l: number): React.JSX.Element => (
            <div className="d-flex w-100 mb-3 pb-3 border-bottom align-items-center" key={l}>
              <div className="w-75 text-danger">
                {locationDisplayName} <i className="sv sv-warning" />
              </div>
              <div className="w-25">
                <Button className="w-100" variant="outline-primary" onClick={() => onAddLocation(locationDisplayName)}>
                  Add Location
                </Button>
              </div>
            </div>
          )
        )}
        {!!missingLocations.length && (
          <p className="text-danger m-0">
            <i>{missingLocations.length} Location&#40;s&#41; Not Found!</i>
          </p>
        )}
        <Modal.Footer className="d-flex justify-content-end">
          <Button variant="secondary" onClick={handleHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isNotValid} onClick={handleSubmit}>
            Continue
          </Button>
        </Modal.Footer>
      </EditModal>
      <EditLocationModal
        show={!!addingLocation}
        data={addingLocation}
        title="Add Location"
        onHide={async () => {
          await handleGetOverrideImportOptions(importId, true);
          setState((current: ManifestOverridesModalState): ManifestOverridesModalState => ({ ...current, addingLocation: undefined }));
        }}
        onSubmit={onCreateLocation}
        options={{
          importId,
        }}
      />
    </>
  );
};

// TODO: This is currently being unused but could be used in the future.
const EditBufferTimesModal = ({ show, onHide, onSubmit }): React.JSX.Element => (
  <Modal show={show} onHide={onHide}>
    <Modal.Body>
      <Modal.Title>Buffer Times</Modal.Title>
      <Row>
        <Col className="w-50" />
        <Col className="d-flex justify-content-around">
          <Button className="disabled" variant="gray">
            Stime
          </Button>
          <Button className="disabled" variant="gray">
            Etime
          </Button>
          <Button className="disabled" variant="gray">
            Value
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="neumorphic neumorphic-in p-2 m-2">
            <Form.Control as="select" value="">
              <option value="" disabled>
                Pickup Location
              </option>
            </Form.Control>
          </div>
        </Col>
        <Col>
          <Card className="rounded p-2 m-1">
            <Row>
              <Col className="d-flex justify-content-around position-relative">
                <Form.Control className="me-1" type="time" />
                <Form.Control className="mx-1" type="time" />
                <Form.Control className="ms-1" type="number" />
                <Button
                  className="mt-1"
                  variant="outline-danger"
                  size="sm"
                  style={{
                    position: 'absolute',
                    right: '-2rem',
                  }}
                >
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-around position-relative">
                <Form.Control className="me-1" type="time" />
                <Form.Control className="mx-1" type="time" />
                <Form.Control className="ms-1" type="number" />
                <Button
                  className="mt-1"
                  variant="outline-success"
                  size="sm"
                  style={{
                    position: 'absolute',
                    right: '-2rem',
                  }}
                >
                  <i className="fa fa-plus" />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="neumorphic neumorphic-in p-2 m-2">
            <Form.Control as="select" value="">
              <option value="" disabled>
                Pickup Location
              </option>
            </Form.Control>
          </div>
        </Col>
        <Col>
          <Card className="rounded p-2 m-1">
            <Row>
              <Col className="d-flex justify-content-around position-relative">
                <Form.Control className="me-1" type="time" />
                <Form.Control className="mx-1" type="time" />
                <Form.Control className="ms-1" type="number" />
                <Button
                  className="mt-1"
                  variant="outline-danger"
                  size="sm"
                  style={{
                    position: 'absolute',
                    right: '-2rem',
                  }}
                >
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-around position-relative">
                <Form.Control className="me-1" type="time" />
                <Form.Control className="mx-1" type="time" />
                <Form.Control className="ms-1" type="number" />
                <Button
                  className="mt-1"
                  variant="outline-success"
                  size="sm"
                  style={{
                    position: 'absolute',
                    right: '-2rem',
                  }}
                >
                  <i className="fa fa-plus" />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Button className="text-gray w-100" variant="outline-light" onClick={() => {}}>
            <i className="fa fa-plus" /> Add Location
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button className="px-5" variant="gray" onClick={() => onSubmit()}>
            Save
          </Button>
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
);

export default ManifestOverridesModal;
