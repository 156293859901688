import { Badge, Button } from 'react-bootstrap';
import { Datetime, generateQueryString, handleError, parseQueryString, titleCase } from '@/utils';
import { IMPORT_TYPES, PENDING_STATUS, TODAY, TODAY_EOD } from '@/constants';
import { ImportStatusEnum, ImportTypeEnum } from '@/models/gen/graphql';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { AirportGroupDropdown } from '@/components/AirportDropdown';
import ConfirmationButton from '@/components/ConfirmationButton';
import DateRangePicker from '@/components/DateRangePicker';
import EnumDropdown from '@/components/EnumDropdown';
import FormButton from '@/components/FormButton';
import ImportProviderDropdown from '@/components/ImportProviderDropdown';
import { PageSettingsModal } from '@/components/Filters';
import QueryFilters from '@/components/QueryFilters';
import UserDropdown from '@/components/UserDropdown';
import { createComponentQueryState } from '@/state';

export type ManifestFiltersState = {
  from: string;
  to: string;
  createdBy: string;
  airportCode: string[];
  providerId: string;
  status: string;
  type: string;
};
export const initManifestFiltersState: ManifestFiltersState = {
  from: TODAY,
  to: TODAY_EOD,
  createdBy: '',
  airportCode: [],
  providerId: '',
  status: '',
  type: '',
};
export type ManifestFiltersProps = {
  selected: string[];
  onSubmit: (filters: ManifestFiltersState) => Promise<void>;
  onReset?: (filters: ManifestFiltersState) => Promise<void>;
  onImport?: () => void;
  onArchive?: () => void;
};

const useManifestFilters = createComponentQueryState(initManifestFiltersState, {
  reviver: (key: string, value: any) => {
    switch (key) {
      case 'type':
        return Object.values(ImportTypeEnum).includes(value) ? value : initManifestFiltersState.type;
      case 'status':
        return Object.values(ImportStatusEnum).includes(value) ? value : initManifestFiltersState.status;
      default: {
        return value;
      }
    }
  },
});

const ManifestFilters = ({ selected, onSubmit, onReset, onImport, onArchive }: ManifestFiltersProps): ReactNode => {
  const [state, setState, saveState] = useManifestFilters(({ state, setState, saveState }) => [state, setState, saveState]);
  const { from, to, createdBy, airportCode, providerId, status, type } = state;
  const [openSettings, setOpenSettings] = useState(false);

  const handleChange = useCallback(
    (name: string) =>
      (value: unknown): void =>
        setState((current: ManifestFiltersState): ManifestFiltersState => ({ ...current, [name]: value })),
    [setState]
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    saveState();
    await onSubmit(state);
  }, [onSubmit, saveState, state]);

  const handleReset = useCallback(async (): Promise<void> => {
    const from = new Datetime().startOf('day').toString();
    const to = new Datetime().endOf('day').toString();
    setState({ ...initManifestFiltersState, from, to });
    saveState();
    onReset({ ...initManifestFiltersState, from, to });
    await onSubmit({ ...initManifestFiltersState, from, to });
  }, [setState, saveState, onReset, onSubmit]);

  const archiveImports = useCallback(async (): Promise<void> => {
    try {
      await onArchive();
      handleSubmit();
    } catch (err) {
      handleError(err);
    }
  }, [onArchive, handleSubmit]);

  useEffect((): void => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryFilters className="justify-content-between">
      <div className="d-flex gap-2">
        <QueryFilters.Control>
          <DateRangePicker
            name="dates"
            value={[from, to] as any}
            onChange={(event) => {
              const [from, to] = event.target.value?.split?.(' - ') || [null, null];
              handleChange('from')(from);
              handleChange('to')(to);
            }}
          />
        </QueryFilters.Control>
        <QueryFilters.Input>
          <UserDropdown
            name="createdBy"
            onChange={handleChange('createdBy')}
            value={createdBy}
            options={{ locale: { 'Select...': 'Imported By' } }}
            airports={airportCode || []}
          />
        </QueryFilters.Input>
        <QueryFilters.Input>
          <AirportGroupDropdown
            name="airportCode"
            onChange={handleChange('airportCode')}
            value={airportCode || []}
            options={{ locale: { 'Select...': 'Airport' } }}
          />
        </QueryFilters.Input>
        <QueryFilters.Input>
          <ImportProviderDropdown
            name="providerId"
            onChange={handleChange('providerId')}
            value={providerId}
            options={{ locale: { 'Select...': 'Airline' } }}
          />
        </QueryFilters.Input>
        <QueryFilters.Input>
          <EnumDropdown
            name="status"
            onChange={handleChange('status')}
            value={status}
            enum={Object.values(PENDING_STATUS).reduce(
              (acc, value) => {
                acc[titleCase(value.replace(/_/g, ' '))] = value;
                return acc;
              },
              {} as Record<string, string>
            )}
            options={{ locale: { 'Select...': 'Status' } }}
          />
        </QueryFilters.Input>
        <QueryFilters.Input>
          <EnumDropdown
            name="type"
            onChange={handleChange('type')}
            value={type}
            enum={Object.values(IMPORT_TYPES).reduce(
              (acc, value) => {
                acc[titleCase(value.replace(/_/g, ' '))] = value;
                return acc;
              },
              {} as Record<string, string>
            )}
            options={{ locale: { 'Select...': 'Type' } }}
          />
        </QueryFilters.Input>
        <QueryFilters.Control>
          <Button variant="success" className="border-white" onClick={handleSubmit}>
            GO
          </Button>
          <Button variant="outline-light" onClick={handleReset}>
            Reset
          </Button>
        </QueryFilters.Control>
      </div>
      <div className="d-flex gap-2">
        <QueryFilters.Control>
          <FormButton
            name="ARCHIVED_ADDONS"
            icon={<i className="sv sv-archive2 {font-size:1.5rem;}" />}
            variant="outline-light"
            to={`/imports/history?${generateQueryString({ ...parseQueryString(window.location.search), status: 'PARSED,NOT_PARSED', type: 'ADDON', archived: true })}`}
          >
            Archived Addons
          </FormButton>
        </QueryFilters.Control>
        {onImport && (
          <QueryFilters.Control>
            <FormButton
              name="IMPORT_SCHEDULES"
              icon={<i className="sv sv-download2 {font-size:1.5rem;}" />}
              variant="outline-light"
              onClick={onImport}
            >
              Import Schedules
            </FormButton>
          </QueryFilters.Control>
        )}
        <QueryFilters.Control>
          <ConfirmationButton
            icon={<i className="sv sv-box {font-size:1.5rem;}" />}
            variant="outline-light"
            disabled={!selected.length}
            onConfirm={archiveImports}
            options={{
              confirmation: {
                Body: {
                  message: `Archiving ${selected?.length} manifest(s). Is this ok?`,
                },
                Footer: {
                  resolve: 'Yes',
                  reject: 'Cancel',
                },
              },
            }}
          >
            Archive
            {selected?.length > 1 && (
              <Badge className="ms-2" pill bg="success">
                {selected?.length}
              </Badge>
            )}
          </ConfirmationButton>
        </QueryFilters.Control>
        <QueryFilters.Control>
          <Button variant="outline-light" onClick={(): void => setOpenSettings(true)}>
            <i className="sv sv-cog {font-size:1.5rem;}" />
          </Button>
        </QueryFilters.Control>
      </div>
      <PageSettingsModal show={openSettings} onHide={(): void => setOpenSettings(false)} name="manifestSettings" />
    </QueryFilters>
  );
};

export default ManifestFilters;
