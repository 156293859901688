import { OverlayTrigger, OverlayTriggerProps, Popover } from 'react-bootstrap';
import React, { ChangeEventHandler } from 'react';

import Calendar from '../Calendar';
import { Datetime } from '../../utils';
import FormField from '../FormField';
import dayjs from 'dayjs';
import useUuid from '../../hooks/useUuid';

interface DateSelectorProps {
  name: string;
  label: string;
  value: string[];
  onChange: ChangeEventHandler<any>;
  valid: boolean;
  selector: dayjs.ManipulateType;
  range?: boolean;
  multiple?: boolean;
  inline?: boolean;
  disabled?: boolean;
  options?: any;
}
const defaultOptions: OverlayTriggerProps = {
  children: undefined,
  overlay: undefined,
  container: document.body,
  trigger: 'click',
  placement: 'auto',
  rootClose: true,
};
const DateSelector = ({
  name,
  label,
  value,
  onChange,
  valid,
  selector,
  range = false,
  multiple = false,
  inline = false,
  disabled = false,
  options = {},
}: DateSelectorProps): JSX.Element => {
  const uuid = useUuid();

  return (
    <>
      <OverlayTrigger
        {...defaultOptions}
        overlay={
          <>
            {!inline && (
              <Popover id={uuid}>
                <Popover.Body>
                  <Calendar name={name} value={value} selector={selector} onChange={onChange} range={range} multiple={multiple} />
                </Popover.Body>
              </Popover>
            )}
          </>
        }
      >
        <div className="{width:100%;}">
          {inline && <Calendar name={name} value={value} selector={selector} onChange={onChange} range={range} multiple={multiple} />}
          <FormField
            name={name || 'dateSelectorInput'}
            value={
              Array.isArray(value)
                ? value?.length > 4
                  ? `${value.length} Dates Selected`
                  : value?.map((date: string): string => new Datetime(date).frontendDate).join(',  ')
                : value
            }
            label={label}
            valid={valid}
            options={options}
            disabled={disabled}
            readOnly
          />
        </div>
      </OverlayTrigger>
    </>
  );
};

export default DateSelector;
